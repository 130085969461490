import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import summer from 'src/assets/img/webp/summer.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "JBL Xtreme": 168,
  "UE Megaboom": 173,
  "Garmin Fenix": 241,
  "Altra Lone Peak ALL-WTHR Mid Hiking Boots": 397,
  "Cuisinart TOA-70": 294,
  "Canon EOS": 209,
  "DJI Mavic 3": 576,
  "Ecobee Smart Thermostat": 586,
  "Soundcore Liberty 3 Pro": 516,
  "Shokz OpenRun Pro": 519,
  "Presto HeatDish Plus Tilt Parabolic Heater": 466
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const SummerIdeas: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/summer-must-haves"
    },
    "headline": "Must Haves for Summer 2024: A Guide to the Season's Best Gear",
    "description": "Discover the hottest picks for Summer 2024, designed to enhance your enjoyment of everything from poolside lounging to outdoor adventures and even those cozy summer nights.",
    "image": summer, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Summer gear, Tech gadgets",
    "keywords": ["summer 2024 gear", "best summer gadgets", "top summer gear", "summer must haves", "outdoor adventure gear", "poolside gadgets", "summer tech gear", "best summer products", "summer essentials", "summer 2024 trends"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Must Haves for Summer 2024: A Guide to the Best Gear",
      "item": "https://www.buyreddit.com/articles/summer-must-haves"
    }
  ]
}
  return (
    <div className="article-page">
      <Helmet>
        <title>Must Haves for Summer 2024: A Guide to the Season's Best Gear</title>
        <meta name="description" content="Discover the hottest picks for Summer 2024, designed to enhance your enjoyment of everything from poolside lounging to outdoor adventures and even those cozy summer nights." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/summer-must-haves" />
        <meta name="keywords" content="summer 2024 gear, best summer gadgets, top summer gear, summer must haves, outdoor adventure gear, poolside gadgets, summer tech gear, best summer products, summer essentials, summer 2024 trends" />
        <meta property="og:title" content="Must Haves for Summer 2024: A Guide to the Season's Best Gear" />
        <meta property="og:description" content="Discover the hottest picks for Summer 2024, designed to enhance your enjoyment of everything from poolside lounging to outdoor adventures and even those cozy summer nights." />
        <meta property="og:image" content={summer} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>

      </Helmet>
      <h1 className="article-title">Must Haves for Summer 2024: A Guide to the Season's Best Gear</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={summer} alt="Summer 2024 Gear" className="article-image" />
      <div className="article-content">
        <p>Summer is finally here, and with it comes the promise of sunshine, adventures, and endless fun. But to truly make the most of those warm days, you need the right gear. We've put together a list of the hottest picks for Summer 2024, designed to enhance your enjoyment of everything from poolside lounging to outdoor adventures and even those cozy summer nights.</p>

        <h2>1. Take the Music With You: JBL Xtreme & UE Megaboom</h2>
        <p>
          <a href={productLinks["JBL Xtreme"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">JBL Xtreme</strong>
          </a>
          {productLinks["JBL Xtreme"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["JBL Xtreme"].discountPercent}% Off)</span>
          )}
          : Summer is all about gathering with friends and family, and what better way to set the mood than with great music? The JBL Xtreme is a powerful, waterproof Bluetooth speaker perfect for pool parties or backyard barbecues. Its long battery life lets the party keep going all night.
        </p>
        <p>
          <a href={productLinks["UE Megaboom"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">UE Megaboom</strong>
          </a>
          {productLinks["UE Megaboom"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["UE Megaboom"].discountPercent}% Off)</span>
          )}
          : For those who prefer a more portable option, the UE Megaboom offers immersive, 360-degree sound in a compact, stylish package. Take it to the beach, on a picnic, or anywhere else your summer adventures take you.
        </p>

        <h2>2. Conquer Any Terrain: Garmin Fenix & Altra Lone Peak ALL-WTHR Mid Hiking Boots</h2>
        <p>
          <a href={productLinks["Garmin Fenix"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Garmin Fenix</strong>
          </a>
          {productLinks["Garmin Fenix"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Garmin Fenix"].discountPercent}% Off)</span>
          )}
          : Summer is the perfect time to explore the outdoors. The Garmin Fenix smartwatch is an essential tool for serious adventurers. Its advanced GPS, heart rate monitor, and durable construction allow you to track your progress and conquer challenging hikes or mountain bike trails.
        </p>
        <p>
          <a href={productLinks["Altra Lone Peak ALL-WTHR Mid Hiking Boots"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Altra Lone Peak ALL-WTHR Mid Hiking Boots</strong>
          </a>
          {productLinks["Altra Lone Peak ALL-WTHR Mid Hiking Boots"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Altra Lone Peak ALL-WTHR Mid Hiking Boots"].discountPercent}% Off)</span>
          )}
          : And don't forget the right footwear! Altra Lone Peak ALL-WTHR Mid Hiking Boots offer a wide toe box for natural foot movement, providing excellent support and stability, even on uneven terrain. These waterproof boots are built for durability, making them perfect for tackling any challenging hike.
        </p>

        <h2>3. Elevate Your Backyard BBQ: Cuisinart TOA-70 AirFryer Oven</h2>
        <p>
          <a href={productLinks["Cuisinart TOA-70"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Cuisinart TOA-70</strong>
          </a>
          {productLinks["Cuisinart TOA-70"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Cuisinart TOA-70"].discountPercent}% Off)</span>
          )}
          : Summer is synonymous with backyard barbecues! The Cuisinart TOA-70 AirFryer Oven will make your BBQ gatherings a breeze. This versatile appliance air-fries, bakes, roasts, and grills, all in one unit. It's a convenient and healthy way to cook up delicious food for the whole family, and you can even experiment with new BBQ recipes.
        </p>

        <h2>4. Capture the Magic of Summer Nights: Canon EOS Rebel T7 DSLR</h2>
        <p>
          <a href={productLinks["Canon EOS"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Canon EOS Rebel T7 DSLR</strong>
          </a>
          {productLinks["Canon EOS"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Canon EOS"].discountPercent}% Off)</span>
          )}
          : From breathtaking sunsets to starry skies, summer nights are full of photo-worthy moments. The Canon EOS Rebel T7 DSLR is a perfect choice for capturing those memories. This user-friendly camera is affordable and offers a range of features, making it ideal for beginners who want to explore the world of photography.
        </p>

        <h2>5. See the World From Above: DJI Mavic 3</h2>
        <p>
          <a href={productLinks["DJI Mavic 3"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">DJI Mavic 3</strong>
          </a>
          {productLinks["DJI Mavic 3"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["DJI Mavic 3"].discountPercent}% Off)</span>
          )}
          : Get ready for a whole new perspective this summer with the DJI Mavic 3 drone. This powerful drone captures stunning aerial footage with its high-quality camera, long flight time, and advanced features like obstacle avoidance and wind resistance. It's a great way to document your summer adventures and create amazing memories.
        </p>

        <h2>6. Keep Your Home Cool and Comfortable: Ecobee Smart Thermostat</h2>
        <p>
          <a href={productLinks["Ecobee Smart Thermostat"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Ecobee Smart Thermostat</strong>
          </a>
          {productLinks["Ecobee Smart Thermostat"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Ecobee Smart Thermostat"].discountPercent}% Off)</span>
          )}
          : Beat the summer heat in style with the Ecobee Smart Thermostat. This smart thermostat allows you to control the temperature in different rooms with its remote sensors, ensuring everyone is comfortable. It's also compatible with HomeKit and offers extensive integrations, making it a perfect choice for tech enthusiasts who want to streamline their smart home experience.
        </p>

        <h2>7. Immerse Yourself in Music: Soundcore Liberty 3 Pro</h2>
        <p>
          <a href={productLinks["Soundcore Liberty 3 Pro"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Soundcore Liberty 3 Pro</strong>
          </a>
          {productLinks["Soundcore Liberty 3 Pro"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Soundcore Liberty 3 Pro"].discountPercent}% Off)</span>
          )}
          : Summer festivals, concerts, and outdoor gatherings are all made better with amazing music. The Soundcore Liberty 3 Pro earbuds deliver exceptional sound quality, with deep bass, crystal-clear highs, and a comfortable fit. Their active noise cancellation feature lets you fully immerse yourself in the music, even in noisy environments.
        </p>

        <h2>8. Stay Connected and Aware: Shokz OpenRun Pro</h2>
        <p>
          <a href={productLinks["Shokz OpenRun Pro"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Shokz OpenRun Pro</strong>
          </a>
          {productLinks["Shokz OpenRun Pro"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Shokz OpenRun Pro"].discountPercent}% Off)</span>
          )}
          : For outdoor enthusiasts who want to enjoy music without sacrificing situational awareness, the Shokz OpenRun Pro bone conduction headphones are a great option. They provide decent sound quality while allowing you to hear what's happening around you. Their durable and waterproof design make them great for summer activities like running, biking, or hiking.
        </p>

        <h2>9. Warm Up Those Summer Evenings: Presto HeatDish Plus Tilt Parabolic Heater</h2>
        <p>
          <a href={productLinks["Presto HeatDish Plus Tilt Parabolic Heater"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Presto HeatDish Plus Tilt Parabolic Heater</strong>
          </a>
          {productLinks["Presto HeatDish Plus Tilt Parabolic Heater"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Presto HeatDish Plus Tilt Parabolic Heater"].discountPercent}% Off)</span>
          )}
          : Summer nights can get chilly, but the Presto HeatDish Plus Tilt Parabolic Heater provides cozy warmth when you need it. This unique heater focuses infrared heat directly onto you, making it an efficient and safe way to stay warm without heating the entire room.
        </p>
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />    </div>
  );
};

export default SummerIdeas;
