import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './ArticlesPage.css';
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/blogimg.webp';
import summer from 'src/assets/img/webp/summer.webp';
import bday from 'src/assets/img/webp/bday.webp';
import graduation from 'src/assets/img/webp/graduation.webp';
import ponce from 'src/assets/img/webp/ponce.webp';
import yosemite from 'src/assets/img/webp/yosemite.webp';
import remotework from 'src/assets/img/webp/remotework.webp';
import smartdevices from 'src/assets/img/webp/smartdevices.webp';
import kitchen from 'src/assets/img/webp/kitchen.webp';
import under25img from 'src/assets/img/webp/under25img.webp';
import under50img from 'src/assets/img/webp/under50img.webp';
import under100img from 'src/assets/img/webp/under100img.webp';
import manstanding from 'src/assets/img/webp/manstanding.webp';
import fitnesspic from 'src/assets/img/webp/fitnesspic.webp';
import smarthuman from 'src/assets/img/webp/smarthuman.webp';
import hairdryerwoman from 'src/assets/img/webp/hairdryerwoman.webp';
import airpurifierfamily from 'src/assets/img/webp/airpurifierfamily.webp';
import july4 from 'src/assets/img/webp/4thofjuly.webp';
import buyredditImage from 'src/assets/img/webp/buyreddit.webp';
import multivitamins from 'src/assets/img/png/multivitamins.png';
import proteinman from 'src/assets/img/png/proteinman.png';
import preworkoutimage from 'src/assets/img/png/preworkoutimage.png';
import bodylotionhuman from 'src/assets/img/png/bodylotionhuman.png';
import fishoilblog from 'src/assets/img/webp/fishoilblog.webp';
import quitweed from 'src/assets/img/webp/quit-weed-backdrop.webp';
import quitnicotine from 'src/assets/img/webp/quitnicotine.webp';
import quitalcohol from 'src/assets/img/webp/quitalcohol.webp';

const articles = [
  {
    title: "Reddit's Top Father's Day Gifts in 2024",
    summary: "Discover the best gift ideas for Father's Day.",
    link: "/articles/fathers-day-gift-ideas",
    image: blogimg,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Must Haves for Summer 2024",
    summary: "Discover the best purchases for summer 2024.",
    link: "/articles/summer-must-haves",
    image: summer,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "The 9 Most Loved Birthday Gifts in 2024",
    summary: "Discover the best gift ideas for birthdays.",
    link: "/articles/birthday-gift-ideas",
    image: bday,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Top Gifts for Graduation 2024: Celebrate a New Chapter with Thoughtful Presents",
    summary: "Discover the best graduation gifts for 2024.",
    link: "/articles/graduation-gifts",
    image: graduation,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Perfect Gifts for Pet Owners in 2024: Making Life Easier and More Enjoyable for You and Your Furry Friend",
    summary: "Discover the best gifts for pet owners in 2024.",
    link: "/articles/pet-gifts",
    image: ponce,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Outdoor Adventure Essentials for 2024",
    summary: "Gear up for your next expedition with the best outdoor adventure essentials of 2024.",
    link: "/articles/adventure-gifts",
    image: yosemite,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Top Tech Gadgets for Remote Work in 2024",
    summary: "Boost your productivity and comfort with these top tech gadgets for remote work in 2024.",
    link: "/articles/remote-work-must-haves",
    image: remotework,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Smart Home Devices to Automate Your Life in 2024",
    summary: "Discover the latest smart home devices that add convenience and security to your home.",
    link: "/articles/smart-devices",
    image: smartdevices,
    lastUpdated: "June 8, 2024"
  },
  {
    title: "Your Kitchen Arsenal: The Ultimate Guide to Must-Have Gadgets for Culinary Conquerors",
    summary: "Explore the best kitchen gadgets to make your cooking journey a breeze.",
    link: "/articles/kitchen-must-haves",
    image: kitchen,
    lastUpdated: "June 8, 2024"
  },  
  {
    title: "Top Gifts Reddit Recommends Under $25: Thoughtful Presents That Don't Break the Bank",
    summary: "Discover the top gifts under $25 as recommended by Reddit users. Find affordable and thoughtful presents that don't break the bank.",
    link: "/articles/under-25-dollars-gifts",
    image: under25img,
    lastUpdated: "June 18, 2024"
  },
  {
    title: "Top Gifts Reddit Recommends Under $50: Where Value Meets Awesome",
    summary: "Discover the top gifts under $50 as recommended by Reddit users. Find affordable and thoughtful presents that don't break the bank.",
    link: "/articles/under-50-dollars-gifts",
    image: under50img,
    lastUpdated: "June 18, 2024"
  },
  {
    title: "Reddit's Favorite Gifts Under $100: Everyone Deserves Quality",
    summary: "Discover the top gifts under $100 as recommended by Reddit users. Find affordable and thoughtful presents that offer incredible value.",
    link: "/articles/under-100-dollars-gifts",
    image: under100img,
    lastUpdated: "June 18, 2024"
  },
  {
    title: "The Benefits of Standing Desks: A Comprehensive Review",
    summary: "Discover the science behind why you should get a standing desk.",
    link: "/articles/standing-desk-benefits",
    image: manstanding,
    lastUpdated: "June 24, 2024"
  },
  {
    title: "The Health Benefits of Fitness Trackers",
    summary: "Discover the science behind why you should get a fitness tracker/watch.",
    link: "/articles/fitness-tracker-benefits",
    image: fitnesspic,
    lastUpdated: "June 24, 2024"
  },
  {
    title: "The Benefits of Smart Locks in 2024",
    summary: "Discover why you should get a smart lock!",
    link: "/articles/smart-lock-benefits",
    image: smarthuman,
    lastUpdated: "June 25, 2024"
  },
  {
    title: "The Benefits of Hair Dryers in 2024",
    summary: "Discover why you should get a hair dryer!",
    link: "/articles/hair-dryer-benefits",
    image: hairdryerwoman,
    lastUpdated: "June 25, 2024"
  },
  {
    title: "The Health Benefits of Air Purifiers",
    summary: "Discover why you should get an air purifier!",
    link: "/articles/air-purifier-benefits",
    image: airpurifierfamily,
    lastUpdated: "June 26, 2024"
  }, 
  {
    title: "Top July 4th Gift Ideas",
    summary: "Celebrate Independence Day with the best!",
    link: "/articles/4th-of-july-gifts",
    image: july4,
    lastUpdated: "June 28, 2024"
  },
  {
    title: "The Power of Reddit + AI for Product Recommendations",
    summary: "The Power of Reddit + AI for Product Recommendations",
    link: "/articles/benefits-of-adding-reddit-to-google-searches",
    image: buyredditImage,
    lastUpdated: "July 29, 2024"
  },{
    title: "The Benefits of Multivitamins: A Comprehensive Review",
    summary: "Discover why you should take multivitamins!",
  link: "/articles/Multivitamin-Benefits",
     image: multivitamins,
    lastUpdated: "August 6, 2024"
},{
  title: "The Benefits of Protein Powder: A Comprehensive Review",
  summary: "Discover why you should take protein powder!",
link: "/articles/protein-powder-benefits",
   image: proteinman,
  lastUpdated: "August 6, 2024"
},{
  title: "The Benefits of Pre-Workout Supplements: A Comprehensive Review",
  summary: "Are Pre-Workout Supplements Worth It?!",
link: "/articles/preworkout-benefits",
   image: preworkoutimage,
  lastUpdated: "August 6, 2024"
},
{
  title: "The Health Benefits of Body Lotion",
  summary: "Are Body Lotions Worth It?!",
link: "/articles/body-lotion-benefits",
   image: bodylotionhuman,
  lastUpdated: "August 6, 2024"
},
{
  title: "The Benefits of Fish Oil: A Comprehensive Review",
  summary: "Is Fish Oil Worth It?!",
link: "/articles/fish-oil-benefits",
   image: fishoilblog,
  lastUpdated: "August 6, 2024"
},
{
  title: "How to Quit Weed in 2024 (With Supplements)",
  summary: "Rise above the fog.",
link: "/how-to-quit-weed",
   image: quitweed,
  lastUpdated: "August 11, 2024"
},
{
  title: "How to Quit Nicotine in 2024 (With Supplements)",
  summary: "Rise above the fog.",
link: "/how-to-quit-nicotine",
   image: quitnicotine,
  lastUpdated: "August 11, 2024"
},
{
  title: "How to Quit Alcohol in 2024 (With Supplements)",
  summary: "Rise above the fog.",
link: "/how-to-quit-alcohol",
   image: quitalcohol,
  lastUpdated: "August 11, 2024"
}
];


const jsonLdData = {
  "@context": "https://schema.org",
  "@type": "ItemList",
  "name": "All Articles",
  "description": "Browse through our collection of articles covering various topics.",
  "itemListElement": articles.map((article, index) => ({
    "@type": "ListItem",
    "position": index + 1,
    "url": `https://www.buyreddit.com${article.link}`,
    "name": article.title,
    "image": article.image
  }))
};

const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    }
  ]
}

const parseDate = (dateString : any) => {
  const [month, day, year] = dateString.split(' ');
  const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
  return new Date(year, monthIndex, parseInt(day));
};
const sortedArticles = [...articles].sort((a, b) => 
  parseDate(b.lastUpdated).getTime() - parseDate(a.lastUpdated).getTime()
);
const ArticlesPage: React.FC = () => {
  return (
    <div className="articles-page">
      <Helmet>
        <title>All Articles - Latest News about the Best Products</title>
        <meta name="description" content="Explore our collection of articles featuring the best products, with insights and recommendations to help you find the perfect item for any occasion!" />
        <link rel="canonical" href="https://www.buyreddit.com/articles" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="articles-title">All Articles</h1>
      <div className="articles-list">
        {sortedArticles.map((article, index) => (
          <div key={index} className="article-card">
            <Link to={article.link}>
              <img src={article.image} alt={article.title} className="article-image" />
              <h2 className="article-card-title">{article.title}</h2>
              <p className="article-last-updated">Last updated: {article.lastUpdated}</p>
            </Link>
          </div>
        ))}
      </div>
      <AppFooter />
    </div>
  );
};

export default ArticlesPage;
