import React, { FunctionComponent } from 'react';
import './TermsofService.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';

const TermsOfService: FunctionComponent = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <p>Last Updated: April 30, 2024</p>
      <h2>1. Introduction and Overview</h2>
      <p>Welcome to BuyReddit, a service provided by Subhash Ventures, Inc ("Subhash Ventures"). BuyReddit offers a platform for aggregating and showcasing top-rated product recommendations from Reddit, helping you make informed purchasing decisions. By using our service, you agree to these Terms of Service ("Terms"), which govern your access to and use of our website, buyreddit.com ("Service"). Please read these Terms carefully before using the Service.</p>
      <h2>2. Eligibility</h2>
      <p>The Service is available to anyone interested in utilizing our product recommendations. There are no age restrictions or requirements for valid email addresses or payment methods for basic use, as the Service does not require registration for access.</p>
      <h2>3. Service Description</h2>
      <p>BuyReddit aggregates and presents product recommendations sourced from various Reddit discussions. Users can view aggregated ratings, comments, and summaries without any need for registration or payment.</p>
      <h2>4. Purchases and Payments</h2>
      <p>BuyReddit is free to use and primarily monetized through affiliate links. When you purchase products through links on our site, we may earn a commission. No other payments or purchases are required to access our Service.</p>
      <h2>5. Intellectual Property</h2>
      <p>The content and data provided through BuyReddit, including but not limited to aggregated product recommendations, reviews, and user-generated content, are for your personal and non-commercial use. You agree not to redistribute, reproduce, or exploit any content from our Service without express permission from us.</p>
      <h2>6. User Conduct</h2>
      <p>You agree to use the Service lawfully and not to use the Service for any unlawful purpose, including but not limited to fraud, intellectual property infringement, or to harass, abuse, or harm others.</p>
      <h2>7. Disclaimers and Limitation of Liability</h2>
      <p>BuyReddit is provided "AS IS" without warranties of any kind. Subhash Ventures will not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Service or from any information obtained from the Service.</p>
      <h2>8. Changes to Terms</h2>
      <p>Subhash Ventures reserves the right to modify these Terms at any time. Your continued use of the Service following such changes will indicate your acceptance of the new Terms.</p>
      <h2>9. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the state of Delaware and the United States, without regard to its conflict of law provisions.</p>
      <h2>10. Contact Information</h2>
      <p>For any questions regarding these Terms, please contact us at support@buyreddit.com.</p>
      <AppFooter />
    </div>
  );
};

export default TermsOfService;
