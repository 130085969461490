import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import birthday from 'src/assets/img/webp/bday.webp';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import { Link } from 'react-router-dom';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "Bose QuietComfort": 131,
  "Moccamaster": 259,
  "Canon EOS": 209,
  "LG 27GP850-B": 147,
  "Garmin Fenix": 241,
  "Sennheiser HD650": 122,
  "JBL Xtreme": 168,
  "Samsung Galaxy Watch": 243,
  "HomePod Mini": 277
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const BirthdayIdeas: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/birthday-gift-ideas"
    },
    "headline": "The Most Loved Birthday Gifts: Beyond the Ordinary",
    "description": "Explore the most loved birthday gifts, spanning various categories to suit a range of tastes and interests.",
    "image": birthday, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Birthday gifts, shopping",
    "keywords": ["Birthday gifts", "loved birthday gifts", "unique gifts", "thoughtful gifts", "tech gifts", "music gifts", "fitness gifts", "coffee gifts", "photography gifts", "gamer gifts"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "The Most Loved Birthday Gifts: Beyond the Ordinary",
      "item": "https://www.buyreddit.com/articles/birthday-gift-ideas"
    }
  ]
}


  return (
    <div className="article-page">
      <Helmet>
        <title>The Most Loved Birthday Gifts: Beyond the Ordinary</title>
        <meta name="description" content="Explore the most loved birthday gifts, spanning various categories to suit a range of tastes and interests." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/birthday-gift-ideas" />
        <meta name="keywords" content="Birthday gifts, loved birthday gifts, unique gifts, thoughtful gifts, tech gifts, music gifts, fitness gifts, coffee gifts, photography gifts, gamer gifts" />
        <meta property="og:title" content="The Most Loved Birthday Gifts: Beyond the Ordinary" />
        <meta property="og:description" content="Explore the most loved birthday gifts, spanning various categories to suit a range of tastes and interests." />
        <meta property="og:image" content={birthday} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Most Loved Birthday Gifts: Beyond the Ordinary</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link> {/* Add link below the title */}
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={birthday} alt="Birthday Gifts" className="article-image" />
      <div className="article-content">
        <p>A birthday is a chance to celebrate the special people in your life. But finding the perfect gift can be tricky. You want something unique, thoughtful, and truly appreciated. Forget the generic gift cards and generic presents – we've compiled a list of the most loved birthday gifts, spanning various categories to suit a range of tastes and interests.</p>
        
        {Object.entries(product_dict).map(([productName, productId], index) => (
          <React.Fragment key={productId}>
            {(index === 2 || index === 5 || index === 8) && productLinks[productName]?.imageUrl && (
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer">
                <img src={productLinks[productName].imageUrl} alt={productName} className="product-image" />
              </a>
            )}
            <h2>{index + 1}. {productName}</h2>
            <p>
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productLinks[productName]?.onSale && (
                <span className="sale-text"> (On Sale Now - {productLinks[productName].discountPercent}% Off)</span>
              )}
              : {getDescriptionForProduct(productName)}
            </p>
          </React.Fragment>
        ))}
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

const getDescriptionForProduct = (productName: string): string => {
  switch (productName) {
    case "Bose QuietComfort":
      return "These headphones are a haven for those who want to block out the world and immerse themselves in music, podcasts, or audiobooks. Their comfortable design and long battery life make them ideal for relaxing at home or on the go.";
    case "Moccamaster":
      return "This coffee maker consistently brews exceptional coffee, thanks to its unique heating system and Dutch design. It's a perfect gift for those who appreciate a truly delicious cup of joe.";
    case "Canon EOS":
      return "This user-friendly DSLR camera is perfect for capturing memories and exploring the world of photography. Its blend of features and affordability makes it a great choice for both beginners and seasoned photographers.";
    case "LG 27GP850-B":
      return "This high-resolution, high-refresh-rate monitor is a true gamer's dream. It delivers a smooth, immersive gaming experience, eliminating screen tearing and stuttering.";
    case "Garmin Fenix":
      return "This robust smartwatch is an essential tool for serious adventurers. Its advanced GPS system, heart rate monitor, and durable design make it perfect for tracking hikes, runs, and bike rides.";
    case "Sennheiser HD650":
      return "These headphones are a true audiophile's dream, delivering a rich, detailed, and natural sound experience. Their wide soundstage and exceptional sound quality make them ideal for enjoying all genres of music.";
    case "JBL Xtreme":
      return "This powerful, waterproof Bluetooth speaker is perfect for parties and outdoor gatherings. Its long battery life and rugged design mean the music can keep going all night long.";
    case "Samsung Galaxy Watch":
      return "This smartwatch offers a range of features, from fitness tracking and health monitoring to smart notifications and app compatibility. It's a great choice for tech enthusiasts who want to stay connected and organized.";
    case "HomePod Mini":
      return "This smart speaker offers incredible sound quality for its size, making it perfect for filling a room with rich audio. It also integrates seamlessly with Apple devices, making it easy to control and enjoy music, podcasts, and more.";
    default:
      return "";
  }
};

export default BirthdayIdeas;
