import React, { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { configureStore, history } from 'store/store';
import '@/assets/sass/main.sass';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConnectedRouter } from 'connected-react-router';

import TopBar from 'components/partials/AppTopNav';
import AppNav from 'components/partials/AppNav';
import AppSearch from 'components/partials/AppSearch';
import ErrorBoundary from 'components/partials/AppErrorBoundary';
import AppScrollRestorator from 'components/partials/AppScrollRestorator/AppScrollResorator';
import FathersDayGiftIdeas from 'pages/Articles/FathersDayGiftIdeas';
import BirthdayIdeas from 'pages/Articles/BirthdayIdeas';
import SummerIdeas from 'pages/Articles/SummerIdeas';
import GraduationGifts from 'pages/Articles/GraduationGifts';
import ArticlesPage from '~/pages/Articles/ArticlesPage';
import PetGifts from '~/pages/Articles/PetGifts';
import AdventureGifts from '~/pages/Articles/AdventureGifts';
import RemoteWorkIdeas from '~/pages/Articles/RemoteWorkIdeas';
import SmartDevices from '~/pages/Articles/SmartDevices';
import KitchensBest from '~/pages/Articles/KitchensBest';
import Under25Gifts from '~/pages/Articles/Under25Gifts';
import Under50Gifts from '~/pages/Articles/Under50Gifts';
import Under100Gifts from '~/pages/Articles/Under100Gifts';
import July4thGifts from '~/pages/Articles/July4thGifts';
import StandingDeskBenefits from '~/pages/Articles/StandingDeskBenefits';
import FitnessTrackerBenefits from '~/pages/Articles/FitnessTrackerBenefits';
import SmartLockBenefits from '~/pages/Articles/SmartLockBenefits';
import HairDryerBenefits from '~/pages/Articles/HairDryerBenefits';
import AirPurifierBenefits from '~/pages/Articles/AirPurifierBenefits';
import BuyRedditArticle from '~/pages/Articles/BuyRedditArticle';
import MultivitaminBenefits from '~/pages/Articles/MultivitaminBenefits';
import ProteinPowderBenefits from '~/pages/Articles/ProteinPowderBenefits';
import PreworkoutBenefits from '~/pages/Articles/PreworkoutBenefits';
import BodyLotionBenefits from '~/pages/Articles/BodyLotionBenefits';
import FishOilBenefits from '~/pages/Articles/FishOilBenefits';
import QuitWeedPage from '~/pages/Articles/QuitWeedPage';
import QuitNicotinePage from '~/pages/Articles/QuitNicotinePage';
import QuitAlcoholPage from '~/pages/Articles/QuitAlcoholPage';

const Error404 = lazy(() => import('pages/Error404'));
const Search = lazy(() => import('pages/Search'));
const PopularContent = lazy(() => import('~/pages/PopularContent'));
const SalePage = lazy(() => import('~/pages/SalePage'));
const CategoryPage = lazy(() => import('~/pages/CategoryPage'));
const ProductPage = lazy(() => import('~/components/ui/ProductPage'));
import PrivacyPolicy from '~/pages/PrivacyPolicy';
import TermsofService from '~/pages/TermsofService';
const Home = React.lazy(() => import('pages/Home'));
const AllCategories = lazy(() => import('pages/AllCategories/AllCategories'));

const useAdjustVH = () => {
  useEffect(() => {
    const adjustVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', adjustVH);
    adjustVH();

    return () => window.removeEventListener('resize', adjustVH);
  }, []);
};

const { store, persistor } = configureStore();
const queryClient = new QueryClient();

const ProductRedirect: React.FC<{ id: string }> = ({ id }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [redirectUrl, setRedirectUrl] = React.useState('');

  React.useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const API_URL = process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL_DEV
          : process.env.REACT_APP_WEBSITE_URL;
        const response = await fetch(`${API_URL}/api/product/${id}`);
        const product = await response.json();
        const newSlug = `${product.category.toLowerCase().replace(/\s+/g, '-')}/${product.name.toLowerCase().replace(/\s+/g, '-')}-${product.product_id}`;
        setRedirectUrl(`/product/${newSlug}`);
      } catch (error) {
        console.error('Failed to fetch product details:', error);
        setRedirectUrl('/');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Redirect to={redirectUrl} />;
};

const MainContent: FunctionComponent = () => {
  const location = useLocation();
  const isStandalonePage = location.pathname === '/signup' || location.pathname === '/login' || location.pathname === '/reset-password';

  return (
    <>
      {!isStandalonePage && (
        <>
          <TopBar />
          <AppNav />
          <AppSearch />
        </>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/search' exact render={() => <Search />} />
          <Route path='/popular' exact render={() => <PopularContent />} />
          <Route path="/articles" exact component={ArticlesPage} />
          <Route path="/articles/graduation-gifts" component={GraduationGifts} />
          <Route path="/articles/fathers-day-gift-ideas" component={FathersDayGiftIdeas} />
          <Route path="/articles/summer-must-haves" component={SummerIdeas} />
          <Route path="/articles/smart-devices" component={SmartDevices} />
          <Route path="/articles/benefits-of-adding-reddit-to-google-searches" component={BuyRedditArticle} />
          <Route path="/articles/remote-work-must-haves" component={RemoteWorkIdeas} />
          <Route path="/articles/under-25-dollars-gifts" component={Under25Gifts} />
          <Route path="/articles/under-50-dollars-gifts" component={Under50Gifts} />
          <Route path="/articles/under-100-dollars-gifts" component={Under100Gifts} />
          <Route path="/articles/4th-of-july-gifts" component={July4thGifts} />
          <Route path="/articles/kitchen-must-haves" component={KitchensBest} />
          <Route path="/articles/pet-gifts" component={PetGifts} />
          <Route path="/articles/fitness-tracker-benefits" component={FitnessTrackerBenefits} />
          <Route path="/articles/fish-oil-benefits" component={FishOilBenefits} />
          <Route path="/articles/air-purifier-benefits" component={AirPurifierBenefits} />
          <Route path="/articles/body-lotion-benefits" component={BodyLotionBenefits} />
          <Route path="/articles/multivitamin-benefits" component={MultivitaminBenefits} />
          <Route path="/articles/preworkout-benefits" component={PreworkoutBenefits} />
          <Route path="/articles/hair-dryer-benefits" component={HairDryerBenefits} />
          <Route path="/articles/protein-powder-benefits" component={ProteinPowderBenefits} />
          <Route path="/articles/smart-lock-benefits" component={SmartLockBenefits} />
          <Route path="/articles/standing-desk-benefits" component={StandingDeskBenefits} />
          <Route path="/articles/adventure-gifts" component={AdventureGifts} />
          <Route path="/articles/birthday-gift-ideas" component={BirthdayIdeas} />
          <Route path="/how-to-quit-weed" component={QuitWeedPage} />
          <Route path="/how-to-quit-nicotine" component={QuitNicotinePage} />
          <Route path="/how-to-quit-alcohol" component={QuitAlcoholPage} />
          <Route path='/sale' exact render={() => <SalePage />} />
          <Route path='/privacy-policy' exact render={() => <PrivacyPolicy />} />
          <Route path='/terms-of-service' exact render={() => <TermsofService />} />
          <Route path='/categories/:categorySlug' exact component={CategoryPage} />
          <Route path='/product/:id(\d+)' render={({ match }) => <ProductRedirect id={match.params.id} />} />
          <Route path='/product/:category/:productSlug-:id' exact component={ProductPage} />
          <Route path='/categories' exact component={AllCategories} />
          <Route component={Error404} />
        </Switch>
      </Suspense>
    </>
  );
};

const App: FunctionComponent = () => {
  useAdjustVH();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <ConnectedRouter history={history}>
              <Route component={AppScrollRestorator} />
              <MainContent />
            </ConnectedRouter>
          </ErrorBoundary>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;