import React, { FunctionComponent, ReactNode } from 'react';
import { cn } from '@bem-react/classname';
import './AppIntro.sass'; // Ensure you create appropriate SASS/SCSS/CSS for this

const b = cn('CategoryIntro');

interface IProps {
  categoryName: React.ReactNode;
  description?: string;
  backdropUrl?: string;
  commentsAnalyzed: React.ReactNode;  // Changed from string to React.ReactNode
  topBrands?: string[];
  additionalText?: ReactNode; // Change from `string` to `ReactNode`
  additionalInfo?: React.ReactNode;
}


const AppIntro: FunctionComponent<IProps> = ({
  categoryName,
  description,
  backdropUrl,
  commentsAnalyzed,
  topBrands,
  additionalInfo,
  additionalText
}) => {
  return (
<div className={b()}>
    <div className={b('Header')}> {/* This is a new wrapper for only the title and image */}
        <h1 className={b('Title')}>{categoryName}</h1>
        {backdropUrl && (
            <div className={b('Backdrop')}>
                <img src={backdropUrl} alt={`Background for ${categoryName}`} />
            </div>
            
        )}
        
    </div>

    {description && <p className={b('Description')}>{description}</p>}
    {commentsAnalyzed && <p className={b('Meta')}>{commentsAnalyzed}</p>}
    {additionalText && <p className="AppIntro-AdditionalText">{additionalText}</p>} {/* Render additional text if provided */}
    {additionalInfo && <p className={b('Meta')}>{additionalInfo}</p>}
</div>

  );
};

export default AppIntro;