import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import ponce from 'src/assets/img/webp/ponce.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
    "Purina Pro Plan": 622,
    "Taste of the Wild": 625,
    "Royal Canin": 624,
    "Tiki Cat": 613,
    "Levoit Air Purifier": 543,
    "BlueAir": 544,
    "Roborock": 344,
    "Shark": 348
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const PetGifts: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/pet-gifts"
    },
    "headline": "Perfect Gifts for Pet Owners in 2024",
    "description": "Discover the perfect gifts for pet owners in 2024, from top-rated pet food to high-quality air purifiers and robot vacuums.",
    "image": ponce, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Pet gifts, shopping",
    "keywords": ["pet gifts", "gifts for pet owners", "dog food", "cat food", "air purifiers", "robot vacuums", "best pet products 2024", "gifts for pet lovers"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Perfect Gifts for Pet Owners in 2024",
      "item": "https://www.buyreddit.com/articles/pet-gifts"
    }
  ]
}

  return (
    <div className="article-page">
      <Helmet>
        <title>Perfect Gifts for Pet Owners in 2024</title>
        <meta name="description" content="Discover the perfect gifts for pet owners in 2024, from top-rated pet food to high-quality air purifiers and robot vacuums." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/pet-gifts" />
        <meta name="keywords" content="pet gifts, gifts for pet owners, dog food, cat food, air purifiers, robot vacuums, best pet products 2024, gifts for pet lovers" />
        <meta property="og:title" content="Perfect Gifts for Pet Owners in 2024" />
        <meta property="og:description" content="Discover the perfect gifts for pet owners in 2024, from top-rated pet food to high-quality air purifiers and robot vacuums." />
        <meta property="og:image" content={ponce} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">Perfect Gifts for Pet Owners in 2024: Making Life Easier and More Enjoyable for You and Your Furry Friend</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link> {/* Add link below the title */}
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={ponce} alt="Gifts for Pet Owners" className="article-image" />
      <div className="article-content">
        <p>Whether you're celebrating a pet parent's birthday, a new puppy or kitten joining the family, or just want to spoil your own furry friend, a thoughtful gift can make a world of difference. This year, consider presents that not only pamper your pet but also make life easier and more enjoyable for both of you. We've compiled a list of must-have gifts for pet owners in 2024, focusing on essential categories like dog food, cat food, air purifiers, and robot vacuums.</p>
        
        {Object.entries(product_dict).map(([productName, productId], index) => (
          <React.Fragment key={productId}>
            {(index === 3 || index === 6 || index === 9) && productLinks[productName]?.imageUrl && (
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer">
                <img src={productLinks[productName].imageUrl} alt={productName} className="product-image" />
              </a>
            )}
            <h2>{index + 1}. {productName}</h2>
            <p>
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productLinks[productName]?.onSale && (
                <span className="sale-text"> (On Sale Now - {productLinks[productName].discountPercent}% Off)</span>
              )}
              : {getDescriptionForProduct(productName)}
            </p>
          </React.Fragment>
        ))}
        <p>Remember, the most thoughtful gifts are those that reflect the unique needs and preferences of the pet and their owner. Happy shopping!</p>
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

const getDescriptionForProduct = (productName: string): string => {
  switch (productName) {
    case "Purina Pro Plan":
      return "This well-respected brand offers a wide range of formulas to cater to your dog's specific needs, from puppies to seniors and those with sensitive stomachs. Their science-backed nutrition is designed for optimal health and well-being.";
    case "Taste of the Wild":
      return "For the dog who enjoys a more adventurous palate, Taste of the Wild offers a variety of flavors and formulas featuring high-quality ingredients and a focus on natural nutrition.";
    case "Royal Canin":
      return "A vet-recommended brand, Royal Canin offers a wide variety of formulas, including options for kittens, seniors, and those with specific dietary needs. Their science-backed approach to cat food ensures your feline friend gets the best possible nutrition.";
    case "Tiki Cat":
      return "This brand focuses on high-quality ingredients and diverse flavors, catering to even the pickiest of cats. Their grain-free options are a great choice for those with sensitive stomachs.";
    case "Levoit Air Purifier":
      return "This purifier effectively removes dust, allergens, and pet dander from the air, creating a healthier environment for both you and your furry friend. Its quiet operation and smart features make it a perfect choice for pet parents who want to improve the air quality in their homes.";
    case "BlueAir":
      return "This purifier is known for its effectiveness in removing pet allergens, dust, and odors. It's a great option for those with multiple pets or who suffer from pet allergies.";
    case "Roborock":
      return "For pet owners who want to keep their floors clean without lifting a finger, a robot vacuum is a life-saver. Roborock vacuums are known for their effective cleaning performance, advanced navigation features, and self-emptying options.";
    case "Shark":
      return "This brand is a popular choice for pet owners, offering powerful vacuums with self-cleaning brush rolls and excellent suction power. They are particularly effective in tackling pet hair.";
    default:
      return "";
  }
};

export default PetGifts;
