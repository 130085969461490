import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css';
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/fishoilblog.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal';

const product_dict = {
  "Thorne Super EPA": 697,
  "NOW": 692,
  "Carlson": 690,
  "Nordic Naturals": 689
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const FishOilBenefits: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/fish-oil-benefits"
    },
    "headline": "The Benefits of Fish Oil: A Comprehensive Review",
    "description": "Explore the scientifically-backed benefits of fish oil, including cardiovascular health, brain function, anti-inflammatory effects, and more.",
    "image": blogimg,
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit
      }
    },
    "datePublished": "2024-08-06T10:00:00-04:00",
    "dateModified": "2024-08-06T10:00:00-04:00",
    "articleSection": "Health, Nutrition, Dietary Supplements",
    "keywords": ["fish oil", "omega-3 fatty acids", "EPA", "DHA", "cardiovascular health", "brain function", "anti-inflammatory"]
  };
  
  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.buyreddit.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Articles",
        "item": "https://www.buyreddit.com/articles"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "The Benefits of Fish Oil: A Comprehensive Review",
        "item": "https://www.buyreddit.com/articles/fish-oil-benefits"
      }
    ]
  }

  return (
    <div className="article-page">
      <Helmet>
        <title>The Health Benefits of Fish Oil</title>
        <meta name="description" content="Explore the scientifically-backed benefits of fish oil, including cardiovascular health, brain function, anti-inflammatory effects, and more." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/fish-oil-benefits" />
        <meta name="keywords" content="fish oil, omega-3 fatty acids, EPA, DHA, cardiovascular health, brain function, anti-inflammatory" />
        <meta property="og:title" content="The Benefits of Fish Oil: A Comprehensive Review" />
        <meta property="og:description" content="Explore the scientifically-backed benefits of fish oil, including cardiovascular health, brain function, anti-inflammatory effects, and more." />
        <meta property="og:image" content={blogimg} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Health Benefits of Fish Oil</h1>
      <h2 className="text-center">Why Should You Consider Fish Oil Supplements?</h2>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: August 6, 2024</p>
      <img src={blogimg} alt="Fish Oil Supplements" className="article-image2" />
      <div className="article-content underlined-links">
      <div className="article-content">
        <h2>Introduction</h2>
        <p>Fish oil, rich in omega-3 fatty acids such as eicosapentaenoic acid (EPA) and docosahexaenoic acid (DHA), has been extensively studied for its potential health benefits. Its one of the most effective supplements, especially for those deficient in DHA/EPA such as vegetarians and vegans. The following synthesis highlights the key findings from multiple research papers on the benefits of fish oil.</p>

        <h2>Key Benefits of Fish Oil</h2>

        <h3 className='greenback'>1. Cardiovascular Health</h3>
        <ul className="highlighted-list">
          <li>Reduces triglyceride levels, benefiting cardiovascular health</li>
          <li>May reduce the risk of cardiovascular events and improve prognosis in various heart conditions</li>
          <li>Has anti-inflammatory and anti-thrombotic effects</li>
        </ul>
        <p><a href="https://doi.org/10.3389/fcvm.2021.802306" target="_blank" rel="noopener noreferrer">Research shows</a> that fish oil supplementation may reduce the risk of cardiovascular events and improve the prognosis for patients with various heart conditions.</p>

        <h3 className='greenback'>2. Brain and Cognitive Function</h3>
        <ul className="highlighted-list">
          <li>DHA is beneficial for brain function and may slow cognitive decline</li>
          <li>Potential benefits in combating depression, bipolar disorder, and schizophrenia</li>
        </ul>
        <p><a href="https://doi.org/10.1080/10408398.2018.1425978" target="_blank" rel="noopener noreferrer">Studies indicate</a> that DHA in fish oil is effective in slowing the rate of cognitive decline and may have effects on depression disorders.</p>

        <h3 className='greenback'>3. Lipid Profile</h3>
        <ul className="highlighted-list">
          <li>Significant reduction in fasting blood triglycerides</li>
          <li>Minimal effects on total, HDL, and LDL cholesterol levels</li>
        </ul>
        <p><a href="https://doi.org/10.1016/j.ijcard.2008.03.092" target="_blank" rel="noopener noreferrer">A meta-analysis found</a> that fish oil supplementation produces a clinically significant dose-dependent reduction of fasting blood triglycerides in hyperlipidemic subjects.</p>

        <h3 className='greenback'>4. Anti-inflammatory Effects</h3>
        <ul className="highlighted-list">
          <li>Reduces production of pro-inflammatory cytokines</li>
          <li>Beneficial in conditions characterized by chronic inflammation</li>
        </ul>
        <p><a href="https://doi.org/10.1054/CLNU.1999.0072" target="_blank" rel="noopener noreferrer">Research suggests</a> that fish oil can reduce inflammatory responses while protecting tissue microperfusion and immunity.</p>

        <h3 className='greenback'>5. Skin Health</h3>
        <ul className="highlighted-list">
          <li>May ameliorate the severity of various skin disorders</li>
          <li>Anti-inflammatory properties beneficial for skin health</li>
        </ul>
        <p><a href="https://doi.org/10.3390/md16080256" target="_blank" rel="noopener noreferrer">A review indicates</a> that fish oil supplementation can potentially improve skin disorders and promote wound healing.</p>

        <h2>Popular Fish Oil Supplement Options</h2>
        <p>If you're considering incorporating fish oil into your diet, here are some popular options, with our <a href="https://www.buyreddit.com/categories/fishoil" target="_blank">full list here</a>:</p>

        {Object.entries(productLinks).map(([productName, productInfo]) => (
          <div key={productName}>
            <h3>
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer" className="product-link">
              <strong className="highlight">
                  {productName}
                </strong>              </a>
              {productInfo.onSale && (
                <span className="sale-text"> (On Sale Now - {productInfo.discountPercent}% Off)</span>
              )}
            </h3>
            {productInfo.imageUrl && (
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer">
                <img src={productInfo.imageUrl} alt={productName} className="product-image" />
              </a>
            )}
          </div>
        ))}

        <h2>Conclusion</h2>
        <p>Fish oil, particularly its omega-3 fatty acids EPA and DHA, offers a range of health benefits. It is effective in improving cardiovascular health by reducing triglyceride levels and has anti-inflammatory properties that can benefit various conditions, including chronic inflammatory diseases and skin disorders. Additionally, fish oil supports brain function, may help in mental health conditions, and enhances growth and caloric efficiency. These multifaceted benefits make fish oil a valuable supplement for promoting overall health and well-being. As with any supplement, it's advisable to consult with a healthcare professional before adding fish oil to your diet, especially if you have any pre-existing health conditions or are taking medications.</p>

        <h2>References</h2>
        <ol className="references-list">
          <li>Ghasemi Fard, S., et al. (2019). How does high DHA fish oil affect health? A systematic review of evidence. Critical Reviews in Food Science and Nutrition, 59(11), 1684-1727. <a href="https://doi.org/10.1080/10408398.2018.1425978" target="_blank" rel="noopener noreferrer">https://doi.org/10.1080/10408398.2018.1425978</a></li>
          <li>Eslick, G. D., et al. (2009). Benefits of fish oil supplementation in hyperlipidemia: a systematic review and meta-analysis. International journal of cardiology, 136(1), 4-16. <a href="https://doi.org/10.1016/j.ijcard.2008.03.092" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.ijcard.2008.03.092</a></li>
          <li>Basilyan, M. (1988). Fish Oil. The Lancet, 332(8623), 1312. <a href="https://doi.org/10.1016/S0140-6736(88)91900-9" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/S0140-6736(88)91900-9</a></li>
          <li>Zulyniak, M. A., et al. (2016). Fish oil regulates blood fatty acid composition and oxylipin levels in healthy humans: A comparison of young and older men. Molecular nutrition & food research, 60(3), 631-641. <a href="https://doi.org/10.1002/mnfr.201500830" target="_blank" rel="noopener noreferrer">https://doi.org/10.1002/mnfr.201500830</a></li>
          <li>Liao, J., et al. (2022). The Effects of Fish Oil on Cardiovascular Diseases: Systematical Evaluation and Recent Advance. Frontiers in Cardiovascular Medicine, 8, 802306. <a href="https://doi.org/10.3389/fcvm.2021.802306" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fcvm.2021.802306</a></li>
          <li>Huang, T. H., et al. (2018). Cosmetic and Therapeutic Applications of Fish Oil's Fatty Acids on the Skin. Marine Drugs, 16(8), 256. <a href="https://doi.org/10.3390/md16080256" target="_blank" rel="noopener noreferrer">https://doi.org/10.3390/md16080256</a></li>
          <li>Connor, S. L., & Connor, W. E. (1997). Are fish oils beneficial in the prevention and treatment of coronary artery disease?. The American journal of clinical nutrition, 66(4), 1020S-1031S. <a href="https://doi.org/10.1093/ajcn/66.4.1020S" target="_blank" rel="noopener noreferrer">https://doi.org/10.1093/ajcn/66.4.1020S</a></li>
          <li>Fürst, P., & Kuhn, K. S. (2000). Fish oil emulsions: what benefits can they bring?. Clinical nutrition, 19(1), 7-14. <a href="https://doi.org/10.1054/CLNU.1999.0072" target="_blank" rel="noopener noreferrer">https://doi.org/10.1054/CLNU.1999.0072</a></li>
        </ol>
      </div>
      </div>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

export default FishOilBenefits;