import React, { FunctionComponent } from 'react';
import { cn } from '@bem-react/classname';
import './AppFooter.sass';
import img from 'src/assets/img/webp/xlogo.webp'; // Import the image

const b = cn('PageFooter');

const AppFooter: FunctionComponent = () => {
  return (
    <footer className={b()}>
      <div className={b('Text')}>
        <p className='smallfooterfont'>
          Made with love in California. <br />
          © Subhash Ventures 2024.{' '}
          Contact: support@buyreddit.com.{' '} <br />
          This site is not affiliated with Reddit.
          <div className={b('LinksWrapper')}>
            <a href="/privacy-policy" className={b('Link')}>Privacy Policy</a>{' '}
            <a href="/terms-of-service" className={b('Link')}>Terms of Service</a>
          </div>
        </p>
      </div>
      <div className={b('Socials')}>
        <a href="https://x.com/BuyReddit" target="_blank" rel="noopener noreferrer">
          {/* Add the imported image here */}
          <img src={img} alt="Follow @BuyReddit on X" width="19" height="19" />
        </a>
      </div>  
    </footer>
  );
};

export default AppFooter;
