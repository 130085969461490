import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppIntro from 'components/ui/AppIntro';
import AppFooter from 'components/partials/AppFooter';
import './QuitAlcoholPage.css';
import quitalcohol from 'src/assets/img/webp/quitalcohol.webp';
import CompactArticleList from 'components/ui/CompactArticleList';

interface Supplement {
  name: string;
  description: string;
}

interface SupplementSection {
  title: "During the Day" | "After Dinner";
  supplements: Supplement[];
}

interface Product {
  product_id: number;
  id: number;
  link: string;
  name: string;
  affiliateurl: string;
  image_url: string;
  price: string;
  mentions: number;
  positive_attributes?: string;
  negative_attributes?: string;
  isCategoryPage: boolean;
  discount_percent?: number;
  on_sale?: boolean;
  original_price?: number;
}

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const QuitAlcoholPage: React.FC = () => {
  const [supplementSections, setSupplementSections] = useState<SupplementSection[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const renderSupplementSection = (section: SupplementSection, products: Product[]) => (
    <div key={section.title} className="supplement-section">
      <h3>{section.title}</h3>
      {section.supplements.map((supplement, index) => (
        <div key={index} className="supplement-info">
          <h4>{supplement.name}</h4>
          <p>{supplement.description}</p>
        </div>
      ))}
      <div className="product-grid mobile-product-grid">
        {products.map((product) => (
          <div key={product.product_id} className="product-tile">
            <a href={product.link} target="_blank" rel="noopener noreferrer">
              <div className="product-image-container">
                <img src={product.image_url} alt={product.name} />
              </div>
              <div className="product-info">
                <h3 className="product-name">{product.name}</h3>
                <p className="product-price">${product.price}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    setSupplementSections([
      {
        title: "During the Day",
        supplements: [
          {
            name: "B-Complex Vitamins",
            description: "B vitamins, especially B1 (Thiamine), B3 (Niacin), and B6 (Pyridoxine), are crucial for supporting liver function and brain health during alcohol cessation. They can help reduce cravings and support overall nervous system function, potentially easing withdrawal symptoms."
          },
          {
            name: "Milk Thistle",
            description: "Known for its liver-protective properties, milk thistle can support liver health and function during the detoxification process. It may help reduce the damage caused by long-term alcohol use and support the liver's natural regeneration processes."
          },
          {
            name: "L-Glutamine",
            description: "This amino acid can help reduce alcohol cravings and support brain function. L-Glutamine may also help in repairing gut lining damage caused by alcohol, potentially improving overall digestive health during the quitting process."
          }
        ]
      },
      {
        title: "After Dinner",
        supplements: [
          {
            name: "Magnesium",
            description: "Magnesium deficiency is common in individuals with alcohol use disorder. Supplementing with magnesium can help reduce anxiety, improve sleep quality, and support overall nervous system function during the withdrawal and recovery process."
          },
          {
            name: "L-Theanine",
            description: "Found naturally in green tea, L-Theanine promotes relaxation without sedation. It can help manage anxiety and stress often experienced during alcohol withdrawal, potentially improving sleep quality and overall mood."
          },
          {
            name: "Melatonin",
            description: "As a natural sleep aid, melatonin can help regulate sleep patterns often disrupted during alcohol withdrawal. It may assist in achieving more restful sleep without the dependence on alcohol, potentially easing insomnia symptoms common in early sobriety."
          }
        ]
      }
    ]);

    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const productList = [
          { product_id: 1, id: 1, link: "https://amzn.to/3M1b0up", name: "Nature Made B-Complex", affiliateurl: "https://amzn.to/3M1b0up", image_url: "https://m.media-amazon.com/images/I/71R8n4VCi8L._AC_SL1500_.jpg", price: "11.39", mentions: 150, isCategoryPage: false },
          { product_id: 2, id: 2, link: "https://amzn.to/3YHytsm", name: "Nature's Bounty Milk Thistle", affiliateurl: "https://amzn.to/3YHytsm", image_url: "https://m.media-amazon.com/images/I/616dBzmBDLL._SL1500_.jpg", price: "9.50", mentions: 120, isCategoryPage: false },
          { product_id: 3, id: 3, link: "https://amzn.to/4dH6oVZ", name: "NOW Sports L-Glutamine", affiliateurl: "https://amzn.to/4dH6oVZ", image_url: "https://m.media-amazon.com/images/I/61IasxDX8tL._AC_SL1000_.jpg", price: "14.38", mentions: 110, isCategoryPage: false },
          { product_id: 4, id: 4, link: "https://amzn.to/3WZI7oH", name: "Nature Made Magnesium Oxide", affiliateurl: "https://amzn.to/3WZI7oH", image_url: "https://m.media-amazon.com/images/I/71gSgExYP7L._AC_SL1500_.jpg", price: "5.22", mentions: 140, isCategoryPage: false },
          { product_id: 5, id: 5, link: "https://amzn.to/3ywBUax", name: "Sports Research L-Theanine", affiliateurl: "https://amzn.to/3ywBUax", image_url: "https://m.media-amazon.com/images/I/51JZbaKAsfL._AC_SL1200_.jpg", price: "17.95", mentions: 130, isCategoryPage: false },
          { product_id: 6, id: 6, link: "https://amzn.to/4dmCbMu", name: "Nature Made Melatonin", affiliateurl: "https://amzn.to/4dmCbMu", image_url: "https://m.media-amazon.com/images/I/71tF+r6G3cL._AC_SL1500_.jpg", price: "9.25", mentions: 100, isCategoryPage: false },
        ];

        setProducts(productList);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error instanceof Error ? error.message : 'An unexpected error occurred');
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  type GroupedProducts = {
    "During the Day": Product[];
    "After Dinner": Product[];
  };

  const groupedProducts: GroupedProducts = {
    "During the Day": products.slice(0, 3),
    "After Dinner": products.slice(3)
  };

  const commentsAnalyzed = (
    <>
      Analyzed <span className="comments-count">{3542}</span> of the most relevant Reddit comments 
      - last updated <span className="comments-count">{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
    </>
  );

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "How to Quit Alcohol in 2024 With Supplements (according to Reddit)",
    "description": "Learn how to quit alcohol in 2024 with advice from Reddit users and recommended supplements.",
    "image": quitalcohol,
    "datePublished": "2024-08-11T00:00:00+00:00",
    "dateModified": "2024-08-11T00:00:00+00:00",
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.buyreddit.com/logo512.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/how-to-quit-alcohol"
    }
  };

  return (
    <div className="quit-alcohol-page">
      <Helmet>
        <title>How to Quit Alcohol in 2024 With Supplements (according to Reddit) | BuyReddit</title>
        <meta name="description" content="Learn how to quit alcohol in 2024 with advice from Reddit users and recommended supplements." />
        <link rel="canonical" href="https://www.buyreddit.com/how-to-quit-alcohol" />
        <meta name="keywords" content="quit alcohol, alcoholism, sobriety, supplements for quitting alcohol, Reddit advice, B-Complex Vitamins, Milk Thistle, L-Glutamine, Magnesium, L-Theanine, Melatonin" />
        <meta property="og:title" content="How to Quit Alcohol in 2024 With Supplements (Reddit Guide)" />
        <meta property="og:description" content="Explore top-rated supplements recommended by Reddit users to help quit alcohol in 2024." />
        <meta property="og:image" content={quitalcohol} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://www.buyreddit.com/how-to-quit-alcohol" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      
      <AppIntro
        categoryName={<h1>How to Quit Alcohol in 2024 <span className="in-order">(With Supplements)</span></h1>}
        description=""
        backdropUrl={quitalcohol}
        commentsAnalyzed={commentsAnalyzed}
      />

      <h1 style={{ display: 'none' }}>
        Quit Alcohol, Alcoholism, Sobriety, Supplements for Quitting Alcohol, Reddit Advice on Quitting Drinking, B-Complex Vitamins for Liver Health, Milk Thistle for Detoxification, L-Glutamine for Cravings, Magnesium for Withdrawal, L-Theanine for Anxiety, Melatonin for Sleep, How to Stop Drinking, Natural Remedies for Alcohol Cessation
      </h1>

      <div className="content-wrapper">
        <div className="left-column">
          <h2>The Supplements You Need</h2>
          {supplementSections.map((section, index) => 
            renderSupplementSection(section, groupedProducts[section.title])
          )}
        </div>

        <div className="right-column">
          <h2>Recommended Brands (Best of the Best)</h2>
          {isLoading ? (
            <p>Loading products...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="product-grid desktop-product-grid">
              {Object.entries(groupedProducts).map(([category, categoryProducts]) => (
                <React.Fragment key={category}>
                  <h3 className="product-category-title">{category}</h3>
                  {categoryProducts.map((product) => (
                    <div key={product.product_id} className="product-tile">
                      <a href={product.link} target="_blank" rel="noopener noreferrer">
                        <div className="product-image-container">
                          <img src={product.image_url} alt={product.name} />
                        </div>
                        <div className="product-info">
                          <h3>{product.name}</h3>
                          <p className="product-price">${product.price}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="compact-article-list">
            <CompactArticleList />
          </div>
        </div>
      </div>

      <AppFooter />
    </div>
  );
};

export default QuitAlcoholPage;