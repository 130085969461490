import { all } from 'redux-saga/effects';

import searchSaga from 'store/search/saga';


export default function* rootSaga() {
  yield all([
    searchSaga()
  ]);
}
