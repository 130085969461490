import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { StateType } from 'typesafe-actions';
import { createBrowserHistory } from 'history';
import { reducer as notifications } from 'react-notification-system-redux';

import { sagaMiddleware, connectedRouterMiddleware } from 'store/middlewares';
import rootSaga from 'store/saga';
import { IS_DEV } from 'utils/env';

import searchReducer from 'store/search/reducer';
import favoriteMoviesReducer from 'store/favorites/reducer';

const movieReducers = combineReducers({
});

const showReducers = combineReducers({
});
export const history = createBrowserHistory();

const rootReducers = combineReducers({
  router: connectRouter(history),
  search: searchReducer,
  movies: movieReducers,
  shows: showReducers,
  favoriteMovies: favoriteMoviesReducer,
  notifications: notifications
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['favoriteMovies'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers as any);

const middlewares = [sagaMiddleware, connectedRouterMiddleware(history)];

export function configureStore(initialState = {}) {
  const enhancers = [applyMiddleware(...middlewares)];
  const composedEnhancers = IS_DEV ? composeWithDevTools(...enhancers) : compose(...enhancers);
  
  const store = createStore(persistedReducer, initialState as any, composedEnhancers as any);

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store as any);

  return { store, persistor };
}

export type ApplicationState = StateType<typeof rootReducers>;
