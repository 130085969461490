import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import remotework from 'src/assets/img/webp/remotework.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "LG 27GP850-B": 147,
  "Secretlab Titan": 438,
  "Herman Miller Aeron": 444,
  "Humanscale Freedom": 440,
  "Keychron K8": 553,
  "Logitech MX Mechanical Mini": 556,
  "Keychron Q1": 555,
  "Logitech MX Master 3": 566,
  "Logitech G Pro X Superlight": 569,
  "IKEA Bekant": 451,
  "Vivo Electric Standing Desk": 457,
  "Husky Workbench": 454
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const RemoteWorkIdeas: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/remote-work-must-haves"
    },
    "headline": "Top Tech Gadgets for Remote Work in 2024",
    "description": "Boost your productivity and comfort with these top tech gadgets for remote work in 2024.",
    "image": remotework, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Remote work, Tech gadgets",
    "keywords": ["remote work gadgets", "productivity gadgets", "ergonomic chairs", "standing desks", "monitors for remote work", "tech gadgets for remote work", "best tech gadgets for 2024"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Top Tech Gadgets for Remote Work in 2024",
      "item": "https://www.buyreddit.com/articles/remote-work-must-haves"
    }
  ]
}

  return (
    <div className="article-page">
      <Helmet>
        <title>Top Tech Gadgets for Remote Work in 2024</title>
        <meta name="description" content="Boost your productivity and comfort with these top tech gadgets for remote work in 2024." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/remote-work-must-haves" />
        <meta name="keywords" content="remote work gadgets, productivity gadgets, ergonomic chairs, standing desks, monitors for remote work, tech gadgets for remote work, best tech gadgets for 2024" />
        <meta property="og:title" content="Top Tech Gadgets for Remote Work in 2024" />
        <meta property="og:description" content="Boost your productivity and comfort with these top tech gadgets for remote work in 2024." />
        <meta property="og:image" content={remotework} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">Top Tech Gadgets for Remote Work in 2024: Boost Your Productivity and Comfort</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link> {/* Add link below the title */}
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={remotework} alt="Remote Work Gadgets" className="article-image" />
      <div className="article-content">
        <p>Remember that deadline? The one that felt like a mountain range, looming over your carefully constructed schedule? You were going to conquer the world in your pajamas, right? But then, reality set in. The kitchen table wasn't exactly ergonomic, and your trusty laptop, well, it started to feel like it was mocking your aching back.</p>
        <p>Suddenly, you were stuck in a battle against screen fatigue, a creeping sense of isolation, and a longing for a workspace that felt more like a haven than a battleground.</p>
        <p>Sound familiar?</p>
        <p>Luckily, you're not alone. And that's why we've rounded up some of the top tech gadgets for remote work in 2024, focusing on those that can enhance your productivity, comfort, and overall work experience. This is tech that's not just about getting things done, it's about getting things done well, while feeling good about it all.</p>

        <h2>1. Monitors for Maximum Productivity:</h2>
        <p>
          <a href={productLinks["LG 27GP850-B"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">LG 27GP850-B</strong>
          </a>
          {productLinks["LG 27GP850-B"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["LG 27GP850-B"].discountPercent}% Off)</span>
          )}
          : This monitor is a great choice for those who need a large, high-resolution display for multitasking. Its 27-inch screen boasts 1440p resolution, delivering incredible clarity and detail. The 144Hz refresh rate ensures smooth, responsive visuals, making it perfect for anyone working with demanding applications. Imagine opening a dozen tabs and still seeing each one perfectly, no blurry edges, no frustrations.
        </p>

        <h2>2. Ergonomic Chairs for Comfort and Support:</h2>
        <p>
          <a href={productLinks["Secretlab Titan"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Secretlab Titan</strong>
          </a>
          {productLinks["Secretlab Titan"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Secretlab Titan"].discountPercent}% Off)</span>
          )}
          : This high-quality gaming chair is perfect for those who spend long hours sitting at their desks. Its comfortable design and adjustable features provide excellent support and ergonomics, minimizing back pain and discomfort. Remember that deadline we talked about? This chair will be your silent partner, keeping you supported and focused until you crush it.
        </p>
        <p>
          <a href={productLinks["Herman Miller Aeron"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Herman Miller Aeron</strong>
          </a>
          {productLinks["Herman Miller Aeron"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Herman Miller Aeron"].discountPercent}% Off)</span>
          )}
          : This iconic chair is known for its durability, comfort, and adjustable features. The Aeron is a long-term investment in your well-being, offering superior comfort and ergonomics. Think of it as your personal throne, designed to make every workday feel a little more like royalty.
        </p>
        <p>
          <a href={productLinks["Humanscale Freedom"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Humanscale Freedom</strong>
          </a>
          {productLinks["Humanscale Freedom"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Humanscale Freedom"].discountPercent}% Off)</span>
          )}
          : This chair is designed for both comfort and movement. It features a unique "sway" mechanism that allows for a range of motion, promoting circulation and reducing stiffness. No more feeling like you're stuck in a chair, the Freedom encourages movement and keeps you feeling energized.
        </p>

        <h2>3. Keyboards for Enhanced Typing and Control:</h2>
        <p>
          <a href={productLinks["Keychron K8"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Keychron K8</strong>
          </a>
          {productLinks["Keychron K8"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Keychron K8"].discountPercent}% Off)</span>
          )}
          : This keyboard offers a great balance of features and affordability. It's a great option for anyone who wants a mechanical keyboard with a high-quality typing experience and a compact design. Typing feels like a pleasure, not a chore, and the compact design keeps your workspace clutter-free.
        </p>
        <p>
          <a href={productLinks["Logitech MX Mechanical Mini"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Logitech MX Mechanical Mini</strong>
          </a>
          {productLinks["Logitech MX Mechanical Mini"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Logitech MX Mechanical Mini"].discountPercent}% Off)</span>
          )}
          : This keyboard offers a unique blend of mechanical typing and wireless connectivity. It's a compact, elegant keyboard ideal for those who want a high-quality typing experience and the convenience of wireless connectivity. Imagine the freedom of a wireless keyboard without sacrificing the satisfying click of mechanical keys.
        </p>
        <p>
          <a href={productLinks["Keychron Q1"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Keychron Q1</strong>
          </a>
          {productLinks["Keychron Q1"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Keychron Q1"].discountPercent}% Off)</span>
          )}
          : For those who want to customize their keyboard, this hot-swappable option is a great choice. The Q1 allows you to easily swap switches and keycaps, letting you personalize your typing experience. Make it yours! Choose the keys and the feel that works best for you.
        </p>

        <h2>4. Mice for Precision and Comfort:</h2>
        <p>
          <a href={productLinks["Logitech MX Master 3"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Logitech MX Master 3</strong>
          </a>
          {productLinks["Logitech MX Master 3"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Logitech MX Master 3"].discountPercent}% Off)</span>
          )}
          : This mouse is designed for maximum comfort and precision. Its ergonomic shape and customizable buttons make it perfect for those who want a comfortable and efficient mouse. Say goodbye to hand cramps and hello to a mouse that feels like an extension of your hand.
        </p>
        <p>
          <a href={productLinks["Logitech G Pro X Superlight"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Logitech G Pro X Superlight</strong>
          </a>
          {productLinks["Logitech G Pro X Superlight"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Logitech G Pro X Superlight"].discountPercent}% Off)</span>
          )}
          : This lightweight gaming mouse is designed for speed and accuracy. Its comfortable shape, precise sensor, and long battery life make it a great choice for those who want a high-performance mouse. If you need to move fast and click accurately, this mouse is your weapon of choice.
        </p>

        <h2>5. Standing Desks for Better Health:</h2>
        <p>
          <a href={productLinks["IKEA Bekant"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">IKEA Bekant</strong>
          </a>
          {productLinks["IKEA Bekant"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["IKEA Bekant"].discountPercent}% Off)</span>
          )}
          : This adjustable desk is a budget-friendly option for those who want to incorporate standing into their workday. Its sleek design and easy height adjustment make it a great choice for maximizing productivity and improving posture. It's a simple way to make a big difference in your well-being.
        </p>
        <p>
          <a href={productLinks["Vivo Electric Standing Desk"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Vivo Electric Standing Desk</strong>
          </a>
          {productLinks["Vivo Electric Standing Desk"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Vivo Electric Standing Desk"].discountPercent}% Off)</span>
          )}
          : This desk offers a more advanced standing desk solution. It features electric height adjustment, allowing you to smoothly transition between sitting and standing positions. It's a great option for those who want a more ergonomic and customizable standing desk.
        </p>
        <p>
          <a href={productLinks["Husky Workbench"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Husky Workbench</strong>
          </a>
          {productLinks["Husky Workbench"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Husky Workbench"].discountPercent}% Off)</span>
          )}
          : This sturdy workbench offers a more industrial-style standing desk option. It's perfect for those who need a durable and stable surface for work or hobbies. Get that industrial vibe in your workspace, with a desk that can handle anything you throw at it.
        </p>
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

export default RemoteWorkIdeas;
