import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css';
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/manstanding.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal';

const product_dict = {
  "Uplift V2": 463,
  "Fully Jarvis Standing Desk": 464,
  "Flexispot E7": 465
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const StandingDeskBenefits: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/standing-desk-benefits"
    },
    "headline": "The Benefits of Standing Desks: A Comprehensive Review",
    "description": "Explore the scientifically-backed benefits of standing desks, including reduced sitting time, health improvements, and potential cognitive enhancements.",
    "image": blogimg,
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit
      }
    },
    "datePublished": "2024-06-15T10:00:00-04:00",
    "dateModified": "2024-06-15T10:00:00-04:00",
    "articleSection": "Health, Productivity, Office Ergonomics",
    "keywords": ["standing desks", "office ergonomics", "sedentary behavior", "workplace health", "productivity", "cognitive function"]
  };
  
  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.buyreddit.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Articles",
        "item": "https://www.buyreddit.com/articles"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "The Benefits of Standing Desks: A Comprehensive Review",
        "item": "https://www.buyreddit.com/articles/standing-desk-benefits"
      }
    ]
  }

  return (
    <div className="article-page">
      <Helmet>
        <title>The Benefits of Standing Desks: A Comprehensive Review</title>
        <meta name="description" content="Explore the scientifically-backed benefits of standing desks, including reduced sitting time, health improvements, and potential cognitive enhancements." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/standing-desk-benefits" />
        <meta name="keywords" content="standing desks, office ergonomics, sedentary behavior, workplace health, productivity, cognitive function" />
        <meta property="og:title" content="The Benefits of Standing Desks: A Comprehensive Review" />
        <meta property="og:description" content="Explore the scientifically-backed benefits of standing desks, including reduced sitting time, health improvements, and potential cognitive enhancements." />
        <meta property="og:image" content={blogimg} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Benefits of Standing Desks: A Comprehensive Review</h1>
      <h2 className="text-center">Are Standing Desks Worth It?</h2>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 24, 2024</p>
      <img src={blogimg} alt="Standing Desk in Office Setting" className="article-image2" />
      <div className="article-content underlined-links">
      <div className="article-content">
        <h2>Key Benefits of Standing Desks</h2>

        <h3 className='greenback'>1. Health Benefits</h3>
        <p>Standing desks have been associated with several health improvements:</p>
        <ul className="highlighted-list">
          <li>Reduced neck and shoulder pain</li>
          <li>Improved subjective health and vitality</li>
          <li>Potential improvements in postprandial glucose levels and HDL cholesterol (more pronounced with treadmill desks)</li>
        </ul>
        <p><a href="https://libkey.io/choose-library/10.3390/ijerph182111604" target="_blank" rel="noopener noreferrer">A study on workplace sit-stand desk interventions</a> found positive effects on health outcomes, particularly in reducing musculoskeletal discomfort.</p>

        <h3 className='greenback'>2. Psychological and Cognitive Benefits</h3>
        <p>While the evidence is mixed, some studies suggest potential psychological and cognitive benefits:</p>
        <ul className="highlighted-list">
          <li>Possible improvements in executive function and working memory in children using stand-biased desks</li>
          <li>No significant negative impact on cognitive performance or task efficiency</li>
        </ul>
        <p><a href="https://libkey.io/choose-library/10.3390/ijerph13010059" target="_blank" rel="noopener noreferrer">Research on stand-biased desks in school settings</a> has shown promising results in terms of neurocognitive benefits for students.</p>

        <h3 className='greenback'>3. Workplace Productivity</h3>
        <p>Contrary to concerns about potential negative impacts on work performance, research indicates that standing desks do not significantly affect productivity:</p>
        <ul className="highlighted-list">
          <li>No substantial difference in cognitive performance between sitting and standing conditions</li>
          <li>Maintained work performance and efficiency</li>
        </ul>
        <p><a href="https://libkey.io/choose-library/10.1080/00140139.2015.1094579" target="_blank" rel="noopener noreferrer">A randomized control trial</a> comparing seated and standing positions in office workers found no significant differences in cognitive effects.</p>

        <h3 className='greenback'>4. Simple and Practical Adjustment</h3>
        <p>Standing desks have proven to be a feasible and scalable intervention for reducing sedentary behavior:</p>
        <ul className="highlighted-list">
          <li>Low-cost standing desks can be effectively implemented in various settings</li>
          <li>High acceptability in both office and classroom environments</li>
          <li>Potential for long-term behavior change, although effects may diminish over time</li>
        </ul>
        <p><a href="https://libkey.io/choose-library/10.1016/j.apergo.2019.102951" target="_blank" rel="noopener noreferrer">A study on low-cost standing desks</a> demonstrated their effectiveness in reducing workplace sitting time.</p>

        <h2>Popular Standing Desk Options</h2>
        <p>If you're considering incorporating a standing desk into your workspace, here are some popular options, with our <a href="https://www.buyreddit.com/categories/standingdesk" target="_blank">full list here</a>:</p>

        {Object.entries(productLinks).map(([productName, productInfo]) => (
          <div key={productName}>
            <h3>
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer" className="product-link">
              <strong className="highlight">
                  {productName}
                </strong>              </a>
              {productInfo.onSale && (
                <span className="sale-text"> (On Sale Now - {productInfo.discountPercent}% Off)</span>
              )}
            </h3>
            {productInfo.imageUrl && (
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer">
                <img src={productInfo.imageUrl} alt={productName} className="product-image" />
              </a>
            )}
          </div>
        ))}
        <h2>References</h2>
<ol className="references-list">
  <li>MacEwen, B. T., MacDonald, D. J., & Burr, J. (2015). A systematic review of standing and treadmill desks in the workplace. Preventive Medicine, 70, 50-58. <a href="https://doi.org/10.1016/j.ypmed.2014.11.011" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.ypmed.2014.11.011</a></li>
  <li>Weatherson, K. A., Wunderlich, K. B., & Faulkner, G. (2019). Impact of a low-cost standing desk on reducing workplace sitting (StandUP UBC): A randomised controlled trial. Applied Ergonomics, 79, 17-24. <a href="https://doi.org/10.1016/j.apergo.2019.102951" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.apergo.2019.102951</a></li>
  <li>Minges, K. E., Chao, A. M., Irwin, M. L., Owen, N., Park, C., Whittemore, R., & Salmon, J. (2016). Classroom Standing Desks and Sedentary Behavior: A Systematic Review. Pediatrics, 137(2), e20153087. <a href="https://doi.org/10.1542/peds.2015-3087" target="_blank" rel="noopener noreferrer">https://doi.org/10.1542/peds.2015-3087</a></li>
  <li>Ma, J., Ma, D., Li, Z., & Kim, H. (2021). Effects of a Workplace Sit–Stand Desk Intervention on Health and Productivity. International Journal of Environmental Research and Public Health, 18(21), 11604. <a href="https://doi.org/10.3390/ijerph182111604" target="_blank" rel="noopener noreferrer">https://doi.org/10.3390/ijerph182111604</a></li>
  <li>Chrisman, M., Wright, R., & Purdy, W. (2021). College Classroom Instructors Can Effectively Promote Standing among Students Provided with Standing Desks. International Journal of Environmental Research and Public Health, 18(9), 4464. <a href="https://doi.org/10.3390/ijerph18094464" target="_blank" rel="noopener noreferrer">https://doi.org/10.3390/ijerph18094464</a></li>
  <li>Mehta, R. K., Shortz, A. E., & Benden, M. E. (2015). Standing Up for Learning: A Pilot Investigation on the Neurocognitive Benefits of Stand-Biased School Desks. International Journal of Environmental Research and Public Health, 13(1), 59. <a href="https://doi.org/10.3390/ijerph13010059" target="_blank" rel="noopener noreferrer">https://doi.org/10.3390/ijerph13010059</a></li>
  <li>Sherry, A. P., Pearson, N., & Clemes, S. A. (2016). The effects of standing desks within the school classroom: A systematic review. Preventive Medicine Reports, 3, 338-347. <a href="https://doi.org/10.1016/j.pmedr.2016.03.016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.pmedr.2016.03.016</a></li>
  <li>Chambers, A. J., Robertson, M. M., & Baker, N. A. (2019). The effect of sit-stand desks on office worker behavioral and health outcomes: A scoping review. Applied Ergonomics, 78, 37-53. <a href="https://doi.org/10.1016/j.apergo.2019.01.015" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.apergo.2019.01.015</a></li>
  <li>Russell, B. A., Summers, M. J., Tranent, P. J., Palmer, M. A., Cooley, P. D., & Pedersen, S. J. (2016). A randomised control trial of the cognitive effects of working in a seated as opposed to a standing position in office workers. Ergonomics, 59(6), 737-744. <a href="https://doi.org/10.1080/00140139.2015.1094579" target="_blank" rel="noopener noreferrer">https://doi.org/10.1080/00140139.2015.1094579</a></li>
  <li>Chau, J. Y., Daley, M., Dunn, S., Srinivasan, A., Do, A., Bauman, A. E., & van der Ploeg, H. P. (2014). The effectiveness of sit-stand workstations for changing office workers' sitting time: results from the Stand@Work randomized controlled trial pilot. International Journal of Behavioral Nutrition and Physical Activity, 11, 127. <a href="https://doi.org/10.1186/s12966-014-0127-7" target="_blank" rel="noopener noreferrer">https://doi.org/10.1186/s12966-014-0127-7</a></li>
</ol>
      </div>
      </div>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

export default StandingDeskBenefits;