import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css';
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/hairdryerwoman.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal';

const product_dict = {
  "Dyson Supersonic": 370,
  "Revlon One-Step Hair Dryer": 376,
  "Shark FlexStyle": 377
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const HairDryerBenefits: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/hair-dryer-benefits"
    },
    "headline": "The Benefits of Hair Dryers",
    "description": "Explore the practical benefits of hair dryers.",
    "image": blogimg,
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit
      }
    },
    "datePublished": "2024-06-25T10:00:00-04:00",
    "dateModified": "2024-06-25T10:00:00-04:00",
    "articleSection": "Home, Bathroom",
    "keywords": ["hair dryer"]
  };
  
  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.buyreddit.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Articles",
        "item": "https://www.buyreddit.com/articles"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "The Benefits of Hair Dryers",
        "item": "https://www.buyreddit.com/articles/hair-dryer-benefits"
      }
    ]
  }
  return (
    <div className="article-page">
      <Helmet>
        <title>The Benefits of Hair Dryers in 2024</title>
        <meta name="description" content="Explore the advantages of hair dryers for men and women, including time-saving, styling versatility, and hair health benefits." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/hair-dryer-benefits" />
        <meta name="keywords" content="hair dryers, blow dryers, hair styling, hair care, beauty tools" />
        <meta property="og:title" content="The Benefits of Hair Dryers" />
        <meta property="og:description" content="Discover the many advantages of using hair dryers for both men and women, from quick drying to improved hair health." />
        <meta property="og:image" content={blogimg} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Benefits of Hair Dryers</h1>
      <h2 className="text-center">Are Hair Dryers Worth It?</h2>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 25, 2024</p>
      <img src={blogimg} alt="Woman using a hair dryer" className="article-image2" />
      <div className="article-content underlined-links">
        <h2>Key Benefits of Hair Dryers</h2>

        <h3 className='greenback'>1. Time-Saving</h3>
        <ul className="highlighted-list">
          <li>Quickly dry hair, especially useful for people with busy schedules</li>
          <li>Particularly beneficial for those with thick or long hair</li>
          <li>Convenient for individuals who shower before work or school</li>
        </ul>

        <h3 className='greenback'>2. Styling Versatility</h3>
        <ul className="highlighted-list">
          <li>Create volume and lift at the roots</li>
          <li>Smooth hair for a sleek look</li>
          <li>Enhance natural curls or waves</li>
          <li>Straighten hair when used with a brush</li>
          <li>Set styles in place</li>
        </ul>

        <h3 className='greenback'>3. Frizz Control</h3>
        <ul className="highlighted-list">
          <li>Seal the hair cuticle</li>
          <li>Direct airflow down the hair shaft</li>
          <li>Use cool air to set the style</li>
        </ul>

        <h3 className='greenback'>4. Improved Hair Health</h3>
        <ul className="highlighted-list">
          <li>Prevent water from swelling and stretching the hair shaft</li>
          <li>Reduce hygral fatigue (repeated swelling and drying of hair)</li>
          <li>Many modern dryers use gentler technologies like ionic or ceramic heating</li>
        </ul>

        <h3 className='greenback'>5. Better Product Absorption</h3>
        <ul className="highlighted-list">
          <li>Help distribute and activate hair products more effectively</li>
          <li>Heat can open the hair cuticle for better treatment penetration</li>
          <li>Evenly distribute leave-in conditioners, heat protectants, and styling products</li>
        </ul>

        <h2>Tips for Healthy Hair Drying</h2>
        <ul className="highlighted-list">
          <li>Use a heat protectant product</li>
          <li>Keep the dryer moving and at least 6 inches from hair</li>
          <li>Use the lowest effective heat setting</li>
          <li>Finish with a cool shot to set the style</li>
        </ul>

        <h2>Popular Hair Dryer Options</h2>
        <p>If you're considering getting the best for your hair, here are some popular options, with our <a href="https://www.buyreddit.com/categories/hairdryer" target="_blank">full list here</a>:</p>

        {Object.entries(productLinks).map(([productName, productInfo]) => (
          <div key={productName}>
            <h3>
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productInfo.onSale && (
                <span className="sale-text">(On Sale Now - {productInfo.discountPercent}% Off)</span>
              )}
            </h3>
            {productInfo.imageUrl && (
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer">
                <img src={productInfo.imageUrl} alt={productName} className="product-image" />
              </a>
            )}
          </div>
        ))}
        
      </div>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

export default HairDryerBenefits;