import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css';
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/airpurifierfamily.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal';

const product_dict = {
  "Levoit Air Purifier": 543,
  "BlueAir": 544,
  "Dyson Air Purifier": 545
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const AirPurifierBenefits: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/air-purifier-benefits"
    },
    "headline": "The Health Benefits of Air Purifiers",
    "description": "Explore the health benefits of air purifiers, including improved indoor air quality, cardiovascular health, and better sleep.",
    "image": blogimg,
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit
      }
    },
    "datePublished": "2024-06-26T10:00:00-04:00",
    "dateModified": "2024-06-26T10:00:00-04:00",
    "articleSection": "Health, Indoor Air Quality",
    "keywords": ["air purifier", "indoor air quality", "health benefits", "respiratory health", "cardiovascular health"]
  };

  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.buyreddit.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Articles",
        "item": "https://www.buyreddit.com/articles"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "The Health Benefits of Air Purifiers",
        "item": "https://www.buyreddit.com/articles/air-purifier-benefits"
      }
    ]
  }

  return (
    <div className="article-page">
      <Helmet>
        <title>The Health Benefits of Air Purifiers</title>
        <meta name="description" content="Discover the health benefits of using air purifiers, including improved indoor air quality, cardiovascular health, and better sleep." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/air-purifier-benefits" />
        <meta name="keywords" content="air purifier, indoor air quality, health benefits, respiratory health, cardiovascular health" />
        <meta property="og:title" content="The Health Benefits of Air Purifiers: A Detailed Review" />
        <meta property="og:description" content="Explore the health benefits of air purifiers, including improved indoor air quality, cardiovascular health, and better sleep." />
        <meta property="og:image" content={blogimg} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Health Benefits of Air Purifiers</h1>
      <h2 className="text-center">Are Air Purifiers Worth It?</h2>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 26, 2024</p>
      <img src={blogimg} alt="Air purifier in a modern room" className="article-image2" />
      <div className="article-content underlined-links">
      <div className="article-content">
        <h2>Key Benefits of Air Purifiers</h2>
        <h3 className='greenback'>1. Reduction in PM2.5 contaminants:</h3>
        <ul className="highlighted-list">
          <li>Air purifiers significantly reduce indoor PM2.5 levels, which can lead to decreased blood pressure and improved respiratory parameters.</li>
          <li>Studies show reductions in PM2.5 ranging from 22.6% to 92.0% with the use of air purifiers.</li>
        </ul>
        <h3 className='greenback'>2. Cardiovascular Health Improvements:</h3>
        <ul className="highlighted-list">
          <li>Air purifiers are associated with reductions in systolic and diastolic blood pressure, as well as improvements in other cardiovascular markers such as pulse pressure and reactive hyperemia index.</li>
          <li>Significant decreases in inflammatory and thrombogenic biomarkers were observed, indicating potential cardiovascular benefits.</li>
        </ul>
        <h3 className='greenback'>3. Respiratory Health Benefits:</h3>
        <ul className="highlighted-list">
          <li>Air purifiers can improve respiratory health by reducing markers of respiratory inflammation and improving lung function parameters such as forced expiratory volume (FEV1) and fractional exhaled nitric oxide (FeNO).</li>
          <li>However, some studies found no significant improvements in pulmonary function despite reductions in PM2.5.</li>
        </ul>
        <h3 className='greenback'>4. Impact on Sleep Quality:</h3>
        <ul className="highlighted-list">
          <li>Improved air quality from air purifiers has been linked to better sleep outcomes, including increased total sleep time and time in bed.</li>
        </ul>
        <h3 className='greenback'>5. Mixed Results and Limitations:</h3>
        <ul className="highlighted-list">
          <li>Some studies report inconsistent or limited evidence for health benefits, highlighting the need for further research with larger sample sizes and longer follow-up periods.</li>
        </ul>

        <h2>Popular Air Purifier Options</h2>
        <p>If you're considering improving your indoor air quality with an air purifier, here are some popular options, with our <a href="https://www.buyreddit.com/categories/airpurifier" target="_blank">full list here</a>:</p>

        {Object.entries(productLinks).map(([productName, productInfo]) => (
          <div key={productName}>
            <h3>
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">{productName}</strong>
              </a>
              {productInfo.onSale && (
               <span className="sale-text"> On Sale! ({productInfo.discountPercent}% off)</span>
              )}
            </h3>
            <a href={productInfo.url} target="_blank" rel="noopener noreferrer">
              <img src={productInfo.imageUrl} alt={productName} className="product-image" />
            </a>
          </div>
        ))}

        <h2>References</h2>
        <ol className="references-list">
  <li>Chen, R. et al. (2015). Cardiopulmonary benefits of reducing indoor particles of outdoor origin: a randomized, double-blind crossover trial of air purifiers. <em>Journal of the American College of Cardiology</em>, 65(21), 2279-2287. <a href="https://doi.org/10.1016/j.jacc.2015.03.549" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jacc.2015.03.549</a></li>
  <li>Chuang, H. C. et al. (2017). Reduction in household fine particulate matter and improvement in respiratory health: a randomized, double-blind crossover trial of air purifiers. <em>Indoor Air</em>, 27(3), 485-494. <a href="https://doi.org/10.1111/ina.12340" target="_blank" rel="noopener noreferrer">https://doi.org/10.1111/ina.12340</a></li>
  <li>Huang, Y. et al. (2022). Effects of air purifiers on blood pressure and vascular function in healthy adults: a randomized crossover trial. <em>Environmental Health Perspectives</em>, 130(1), 017001. <a href="https://doi.org/10.1289/EHP9383" target="_blank" rel="noopener noreferrer">https://doi.org/10.1289/EHP9383</a></li>
  <li>Zhou, X. et al. (2020). Short-term air purifier intervention benefits patients with chronic rhinosinusitis: a randomized, double-blind, placebo-controlled crossover study. <em>Clinical and Translational Allergy</em>, 10(1), 1-10. <a href="https://doi.org/10.1186/s13601-020-00327-8" target="_blank" rel="noopener noreferrer">https://doi.org/10.1186/s13601-020-00327-8</a></li>
</ol>

      </div>
      </div>
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
      <AppFooter />
    </div>
  );
}

export default AirPurifierBenefits;
