import headphonebackdrop from 'src/assets/img/webp/headphonebackdrop.webp';
import monitorbackdrop from 'src/assets/img/webp/monitorbackdrop.webp';
import camerabackdrop from 'src/assets/img/webp/camerabackdrop.webp';
import portablespeakerbackdrop from 'src/assets/img/webp/portablespeakerbackdrop.webp';
import homespeakersbackdrop from 'src/assets/img/webp/homespeakersbackdrop.webp';
import soundbarbackdrop from 'src/assets/img/webp/soundbarbackdrop.webp';
import fitnesstrackerbackdrop from 'src/assets/img/webp/fitnesstrackerbackdrop.webp';
import coffeemakerbackdrop from 'src/assets/img/webp/coffeemakerbackdrop.webp';
import blenderbackdrop from 'src/assets/img/webp/blenderbackdrop.webp';
import airfryerbackdrop from 'src/assets/img/webp/airfryerbackdrop.webp';
import robotvacuumbackdrop from 'src/assets/img/webp/robotvacuumbackdrop.webp';
import handheldvacuumbackdrop from 'src/assets/img/webp/handheldvacuumbackdrop.webp';
import shavingrazorbackdrop from 'src/assets/img/webp/shavingrazorbackdrop.webp';
import hikingbootsbackdrop from 'src/assets/img/webp/hikingbootsbackdrop.webp';
import dashcambackdrop from 'src/assets/img/webp/dashcambackdrop.webp';
import printerbackdrop from 'src/assets/img/webp/printerbackdrop.webp';
import chairbackdrop from 'src/assets/img/webp/chairbackdrop.webp';
import standingdeskbackdrop from 'src/assets/img/webp/standingdeskbackdrop.webp';
import heaterbackdrop from 'src/assets/img/webp/heaterbackdrop.webp';
import thermostatbackdrop from 'src/assets/img/webp/thermostatbackdrop.webp';
import fanbackdrop from 'src/assets/img/webp/fanbackdrop.webp';
import homesecuritybackdrop from 'src/assets/img/webp/homesecuritybackdrop.webp';
import babymonitorbackdrop from 'src/assets/img/webp/babymonitorbackdrop.webp';
import keyboardbackdrop from 'src/assets/img/webp/keyboardbackdrop.webp';
import mousebackdrop from 'src/assets/img/webp/mousebackdrop.webp';
import lawnmowerbackdrop from 'src/assets/img/webp/lawnmowerbackdrop.webp';
import gaminglaptopbackdrop from 'src/assets/img/webp/gaminglaptopbackdrop.webp';
import wirelessearbudsbackdrop from 'src/assets/img/webp/wirelessearbudsbackdrop.webp';
import smartlockbackdrop from 'src/assets/img/webp/smartlockbackdrop.webp';
import dronesbackdrop from 'src/assets/img/webp/dronesbackdrop.webp';
import airpurifierbackdrop from 'src/assets/img/webp/airpurifierbackdrop.webp';
import hairdryerbackdrop from 'src/assets/img/webp/hairdryerbackdrop.webp';
import dogfoodbackdrop from 'src/assets/img/webp/dogfoodbackdrop.webp';
import catfoodbackdrop from 'src/assets/img/webp/catfoodbackdrop.webp';
import phonecasebackdrop from 'src/assets/img/webp/phonecasebackdrop.webp';
import multivitamin from 'src/assets/img/webp/multivitamin.webp';
import proteinpowder from 'src/assets/img/webp/proteinpowder.webp';
import fishoil from 'src/assets/img/webp/fishoil.webp';
import preworkout from 'src/assets/img/webp/preworkout.webp';
import bodylotion from 'src/assets/img/webp/bodylotion.webp';
import watches from 'src/assets/img/webp/watches.webp';

export type CategoryDetail = {
  name: string;
  description: string;
  imageUrl: string;
  detail: React.ReactNode;
};

export interface CategoryData {
  [key: string]: CategoryDetail;
}

const categoryData: CategoryData = {
  headphones: {
    name: "Headphones",
    description: "The headphones redditors love the most in order.", 
    imageUrl: headphonebackdrop,
    detail: "Analyzed xxxxx of the most relevant reddit comments - last updated on xxxx, xxx"
  },
  earbuds: {
    name: "Earbuds",
    description: "",
    imageUrl: wirelessearbudsbackdrop, 
    detail: ""
  },
  monitors: {
    name: "Monitors",
    description: "The monitors redditors love the most in order.",
    imageUrl: monitorbackdrop,
    detail: "Analyzed xxxxx of the most relevant reddit comments - last updated on xxxx, xxx"
  },
  cameras: {
    name: "Cameras",
    description: "The cameras redditors love the most in order.",
    imageUrl: camerabackdrop,
    detail: "Detailed description about Cameras category."
  },
  portablespeaker: {
    name: "Portable Speakers",
    description: "The portable speakers redditors love the most in order.",
    imageUrl: portablespeakerbackdrop,
    detail: "Explore the best portable speakers."
  },
  airpurifier: {
    name: "Air Purifiers",
    description: "none",
    imageUrl: airpurifierbackdrop,
    detail: "none"
  },
  phonecase: {
    name: "Phone Case Brands",
    description: "none",
    imageUrl: phonecasebackdrop,
    detail: "none"
  },
  homespeakers: {
    name: "Home Speakers",
    description: "The home speakers redditors love the most in order.",
    imageUrl: homespeakersbackdrop,
    detail: "Find out more about home speaker options."
  },
  soundbar: {
    name: "Soundbars",
    description: "Improve your TV sound with these great soundbars.",
    imageUrl: soundbarbackdrop,
    detail: "Detailed description about Soundbars category."
  },
  gaminglaptop: {
    name: "Gaming Laptops",
    description: "",
    imageUrl: gaminglaptopbackdrop,
    detail: ""
  },
  keyboard: {
    name: "Keyboards",
    description: "",
    imageUrl: keyboardbackdrop,
    detail: ""
  },
  mouse: {
    name: "Mouse",
    description: "",
    imageUrl: mousebackdrop,
    detail: ""
  },
  fitnesstracker: {
    name: "Fitness Trackers",
    description: "Track your fitness and health with advanced wearables.",
    imageUrl: fitnesstrackerbackdrop,
    detail: "Explore wearable fitness technology."
  },
  dogfood: {
    name: "Dog Food",
    description: "Track your fitness and health with advanced wearables.",
    imageUrl: dogfoodbackdrop,
    detail: "Explore wearable fitness technology."
  },
  catfood: {
    name: "Cat Food",
    description: "Track your fitness and health with advanced wearables.",
    imageUrl: catfoodbackdrop,
    detail: "Explore wearable fitness technology."
  },
  coffeemaker: {
    name: "Coffee Makers",
    description: "Brew perfect coffee every time with these top coffee makers.",
    imageUrl: coffeemakerbackdrop,
    detail: "Discover the best coffee makers for your morning routine."
  },
  blender: {
    name: "Blenders",
    description: "Create delicious smoothies and meals with powerful blenders.",
    imageUrl: blenderbackdrop,
    detail: "Learn about the most effective kitchen blenders."
  },
  airfryer: {
    name: "Air Fryers",
    description: "Enjoy healthier fried foods with the latest air fryers.",
    imageUrl: airfryerbackdrop,
    detail: "Find out how air fryers can change the way you cook."
  },
  robotvacuum: {
    name: "Robot Vacuums",
    description: "Keep your floors pristine with minimal effort using robot vacuums.",
    imageUrl: robotvacuumbackdrop,
    detail: "Detailed description about Robot Vacuums category."
  },
  handheldvacuum: {
    name: "Vacuums",
    description: "Quick clean-ups made easy with handheld vacuums.",
    imageUrl: handheldvacuumbackdrop,
    detail: "Explore powerful and convenient handheld vacuums."
  },
  hairdryer: {
    name: "Hair Dryers",
    description: "Experience a closer, smoother shave with top electric shavers.",
    imageUrl: hairdryerbackdrop,
    detail: "Discover the best electric shavers for men."
  },
  shavingrazor: {
    name: "Shaving Razors",
    description: "Experience a closer, smoother shave with top electric shavers.",
    imageUrl: shavingrazorbackdrop,
    detail: "Discover the best electric shavers for men."
  },
  hikingboots: {
    name: "Hiking Boots",
    description: "Durable hiking boots to take on any trail.",
    imageUrl: hikingbootsbackdrop,
    detail: "Find the perfect boots for your next hiking trip."
  },
  dashcam: {
    name: "Dash Cams",
    description: "Protect yourself on the road with reliable dash cams.",
    imageUrl: dashcambackdrop,
    detail: "Learn how dash cams can provide security and peace of mind while driving."
  },
  smartlock: {
    name: "Smart Locks",
    description: "",
    imageUrl: smartlockbackdrop,
    detail: "" 
  },
  thermostat: {
    name: "Smart Thermostat",
    description: "",
    imageUrl: thermostatbackdrop,
    detail: ""
  },
  printer: {
    name: "Printers",
    description: "High-quality printers for home and office use.",
    imageUrl: printerbackdrop,
    detail: "Explore the best printers for every printing need."
  },
  chair: {
    name: "Office Chairs",
    description: "Stay comfortable during long working hours with ergonomic chairs.",
    imageUrl: chairbackdrop,
    detail: "Detailed description about Ergonomic Chairs category."
  },
  standingdesk: {
    name: "Standing Desks",
    description: "Enhance your workspace with adjustable standing desks.",
    imageUrl: standingdeskbackdrop,
    detail: "Discover the health benefits of standing desks."
  },
  heater: {
    name: "Heaters",
    description: "Keep warm during the cold months with efficient heaters.",
    imageUrl: heaterbackdrop,
    detail: "Find out which heater is right for your space."
  },
  fan: {
    name: "Fans",
    description: "Cool down effectively with high-performance fans.",
    imageUrl: fanbackdrop,
    detail: "Explore our selection of fans to beat the heat."
  },
  homesecuritycamera: {
    name: "Home Security Cameras",
    description: "Secure your home with state-of-the-art security cameras.",
    imageUrl: homesecuritybackdrop,
    detail: "Understand the features and benefits of home security cameras."
  },
  lawnmower: {
    name: "Lawn Mowers",
    description: "",
    imageUrl: lawnmowerbackdrop,
    detail: ""
  },
  babymonitor: {
    name: "Baby Monitors",
    description: "Keep an eye on your little ones with reliable baby monitors.",
    imageUrl: babymonitorbackdrop,
    detail: "Find the best baby monitors for peace of mind."
  },
  drone: {
    name: "Drones",
    description: "",
    imageUrl: dronesbackdrop,
    detail: ""
  },
  multivitamin: {
    name: "Multivitamins",
    description: "",
    imageUrl: multivitamin,
    detail: ""
  },
  proteinpowder: {
    name: "Protein Powder",
    description: "",
    imageUrl: proteinpowder,
    detail: ""
  },
  fishoil: {
    name: "Fish Oil",
    description: "",
    imageUrl: fishoil,
    detail: ""
  },  preworkout: {
    name: "Pre Workout",
    description: "",
    imageUrl: preworkout,
    detail: ""
  },  bodylotion: {
    name: "Body Lotion",
    description: "",
    imageUrl: bodylotion,
    detail: ""
  },
  watches: {
    name: "Watches",
    description: "",
    imageUrl: watches,
    detail: ""
  }
};

export default categoryData;
