import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/blogimg.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "Sennheiser HD650": 122,
  "Hifiman Sundara": 128,
  "LG 27GP850-B": 147,
  "Gigabyte M27Q": 148,
  "Moccamaster": 259,
  "Aeropress": 260,
  "Garmin Forerunner": 240,
  "Whoop": 245,
  "Infant Optics DXR-8 Pro": 505,
  "AirPods Pro": 510,
  "Schlage Encode": 534,
  "Levoit Air Purifier": 543
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const FathersDayGiftIdeas: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/fathers-day-gift-ideas"
    },
    "headline": "Reddit's Top Father's Day Gifts in 2024",
    "description": "Discover the top Father's Day gift ideas for 2024 as recommended by Reddit users. From high-end headphones to cutting-edge fitness trackers, find unique and thoughtful gifts for every dad.",
    "image": blogimg, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Father's Day gifts, shopping",
    "keywords": ["Father's Day gifts", "best gifts for dad", "unique gifts for Father's Day", "tech gifts for dad", "fitness gifts for dad", "audiophile gifts", "Reddit gift recommendations", "top Father's Day gifts 2024"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Reddit's Top Father's Day Gifts in 2024",
      "item": "https://www.buyreddit.com/articles/fathers-day-gift-ideas"
    }
  ]
}

  return (
    <div className="article-page">
      <Helmet>
        <title>Reddit's Top Father's Day Gifts in 2024</title>
        <meta name="description" content="Discover the top Father's Day gift ideas for 2024 as recommended by Reddit users. From high-end headphones to cutting-edge fitness trackers, find unique and thoughtful gifts for every dad." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/fathers-day-gift-ideas" />
        <meta name="keywords" content="Father's Day gifts, best gifts for dad, unique gifts for Father's Day, tech gifts for dad, fitness gifts for dad, audiophile gifts, Reddit gift recommendations, top Father's Day gifts 2024" />
        <meta property="og:title" content="Reddit's Top Father's Day Gifts in 2024" />
        <meta property="og:description" content="Discover the top Father's Day gift ideas for 2024 as recommended by Reddit users. From high-end headphones to cutting-edge fitness trackers, find unique and thoughtful gifts for every dad." />
        <meta property="og:image" content={blogimg} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">Reddit's Top Father's Day Gifts in 2024</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link> {/* Add link below the title */}
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={blogimg} alt="Father's Day Gift Ideas" className="article-image" />
      <div className="article-content">
        <p>Father's Day is around the corner, and you're on the hunt for the perfect gift to show Dad how much you care. But with so many options, it can be hard to find something truly unique and meaningful. This year, ditch the generic ties and socks and opt for a gift that speaks to his passions and interests. We've curated a list of 12 unique and thoughtful gifts that will impress any dad or dad-to-be in 2024, from audiophiles to tech enthusiasts and even the future dads-to-be.</p>
        
        <h2>For the Music Lover:</h2>
        <p>
          <a href={productLinks["Sennheiser HD650"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Sennheiser HD650</strong>
          </a>
          {productLinks["Sennheiser HD650"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Sennheiser HD650"]?.discountPercent}% Off)</span>
          )}
          : Forget about the average, run-of-the-mill headphones. These babies are built for serious audiophiles. The Sennheiser HD650s are renowned for their exceptional sound quality, delivering a rich, detailed, and nuanced listening experience. They're known for their wide soundstage, clear highs, and natural, accurate bass, making them ideal for enjoying all genres of music. These aren't just headphones, they're an investment in pure sonic pleasure.
        </p>
        {productLinks["Sennheiser HD650"]?.imageUrl && (
          <a href={productLinks["Sennheiser HD650"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Sennheiser HD650"].imageUrl} alt="Sennheiser HD650" className="product-image" />
          </a>
        )}

        <p>
          <a href={productLinks["Hifiman Sundara"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Hifiman Sundara</strong>
          </a>
          {productLinks["Hifiman Sundara"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Hifiman Sundara"]?.discountPercent}% Off)</span>
          )}
          : For the music lover on a budget, the Hifiman Sundara is a compelling alternative. This headphone offers an impressive soundstage and detail retrieval, with a wide frequency response that lets you hear every layer of your favorite tracks. They're a fantastic choice for those who want to experience a step up from standard headphones without breaking the bank.
        </p>
        {productLinks["Hifiman Sundara"]?.imageUrl && (
          <a href={productLinks["Hifiman Sundara"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Hifiman Sundara"].imageUrl} alt="Hifiman Sundara" className="product-image" />
          </a>
        )}

        <h2>For the Gamer:</h2>
        <p>
          <a href={productLinks["LG 27GP850-B"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">LG 27GP850-B</strong>
          </a>
          {productLinks["LG 27GP850-B"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["LG 27GP850-B"]?.discountPercent}% Off)</span>
          )}
          : Level up your dad's gaming experience with the LG 27GP850-B monitor. This 27-inch display boasts a stunning 1440p resolution, offering incredible clarity and detail. With a blistering 144Hz refresh rate, this monitor ensures smooth, responsive gameplay, eliminating screen tearing and stuttering for a truly immersive experience. Plus, it's compatible with PS5, making it a versatile option for both PC and console gaming.
        </p>
        {productLinks["LG 27GP850-B"]?.imageUrl && (
          <a href={productLinks["LG 27GP850-B"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["LG 27GP850-B"].imageUrl} alt="LG 27GP850-B" className="product-image" />
          </a>
        )}

        <p>
          <a href={productLinks["Gigabyte M27Q"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Gigabyte M27Q</strong>
          </a>
          {productLinks["Gigabyte M27Q"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Gigabyte M27Q"]?.discountPercent}% Off)</span>
          )}
          : For dads who love a fast-paced, competitive gaming experience, the Gigabyte M27Q monitor delivers. Its 1440p resolution and 170Hz refresh rate deliver ultra-smooth visuals, while its super-fast 1ms response time ensures that every click and keystroke translates into lightning-fast in-game actions. The M27Q is a true gamer's dream, designed to give Dad the competitive edge.
        </p>
        {productLinks["Gigabyte M27Q"]?.imageUrl && (
          <a href={productLinks["Gigabyte M27Q"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Gigabyte M27Q"].imageUrl} alt="Gigabyte M27Q" className="product-image" />
          </a>
        )}

        <h2>For the Coffee Connoisseur:</h2>
        <p>
          <a href={productLinks["Moccamaster"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Moccamaster</strong>
          </a>
          {productLinks["Moccamaster"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Moccamaster"]?.discountPercent}% Off)</span>
          )}
          : Upgrade Dad's coffee routine with a Moccamaster coffee maker. This Dutch-designed machine is a favorite among coffee connoisseurs, renowned for its ability to consistently brew a perfect cup. Its unique heating system ensures that the water reaches the ideal temperature for extracting the optimal flavor from your favorite beans, resulting in a rich, aromatic, and incredibly flavorful brew. It's a gift that will be appreciated every morning.
        </p>
        {productLinks["Moccamaster"]?.imageUrl && (
          <a href={productLinks["Moccamaster"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Moccamaster"].imageUrl} alt="Moccamaster" className="product-image" />
          </a>
        )}

        <p>
          <a href={productLinks["Aeropress"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Aeropress</strong>
          </a>
          {productLinks["Aeropress"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Aeropress"]?.discountPercent}% Off)</span>
          )}
          : For the coffee lover who's always on the go, the Aeropress is the perfect solution. This compact and portable brewer allows you to create a strong, delicious cup of coffee anywhere. It's a simple, efficient, and versatile brewing method that's great for both at-home and on-the-road coffee enthusiasts.
        </p>
        {productLinks["Aeropress"]?.imageUrl && (
          <a href={productLinks["Aeropress"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Aeropress"].imageUrl} alt="Aeropress" className="product-image" />
          </a>
        )}

        <h2>For the Fitness Enthusiast:</h2>
        <p>
          <a href={productLinks["Garmin Forerunner"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Garmin Forerunner</strong>
          </a>
          {productLinks["Garmin Forerunner"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Garmin Forerunner"]?.discountPercent}% Off)</span>
          )}
          : For the dad who runs, the Garmin Forerunner is a must-have. This fitness tracker is a dedicated running companion, featuring an advanced GPS system that accurately tracks your distance, pace, and time. Its built-in heart rate monitor lets you monitor your workout intensity, while its long battery life ensures that you can track multiple runs without needing to charge.
        </p>
        {productLinks["Garmin Forerunner"]?.imageUrl && (
          <a href={productLinks["Garmin Forerunner"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Garmin Forerunner"].imageUrl} alt="Garmin Forerunner" className="product-image" />
          </a>
        )}

        <p>
          <a href={productLinks["Whoop"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Whoop</strong>
          </a>
          {productLinks["Whoop"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Whoop"]?.discountPercent}% Off)</span>
          )}
          : For the dad who wants to understand his body better, the Whoop is a game-changer. This fitness tracker goes beyond basic activity tracking and provides insights into your sleep quality, recovery, and overall health. It uses a unique subscription model that delivers personalized data and guidance, helping you optimize your fitness journey and reach new heights.
        </p>
        {productLinks["Whoop"]?.imageUrl && (
          <a href={productLinks["Whoop"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Whoop"].imageUrl} alt="Whoop" className="product-image" />
          </a>
        )}

        <h2>For the New Dad:</h2>
        <p>
          <a href={productLinks["Infant Optics DXR-8 Pro"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Infant Optics DXR-8 Pro</strong>
          </a>
          {productLinks["Infant Optics DXR-8 Pro"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Infant Optics DXR-8 Pro"]?.discountPercent}% Off)</span>
          )}
          : Navigating the world of parenthood can be challenging, but the Infant Optics DXR-8 Pro baby monitor offers peace of mind. This monitor provides high-resolution video and crystal-clear audio, allowing you to keep a watchful eye on your little one. Its intuitive interface and long battery life make it easy to use, while its advanced features like night vision and temperature monitoring ensure that you can always stay informed.
        </p>
        {productLinks["Infant Optics DXR-8 Pro"]?.imageUrl && (
          <a href={productLinks["Infant Optics DXR-8 Pro"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Infant Optics DXR-8 Pro"].imageUrl} alt="Infant Optics DXR-8 Pro" className="product-image" />
          </a>
        )}

        <p>
          <a href={productLinks["AirPods Pro"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">AirPods Pro</strong>
          </a>
          {productLinks["AirPods Pro"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["AirPods Pro"]?.discountPercent}% Off)</span>
          )}
          : New dads are busy people, but staying connected is essential. The AirPods Pro are a fantastic gift for a new dad who wants to stay in touch without missing a beat. Their impressive noise cancellation feature lets you focus on conversations or podcasts, even when surrounded by the chaos of a baby's sleep. The transparency mode allows you to quickly hear your surroundings without removing your earbuds, making it easy to stay alert while your little one naps.
        </p>
        {productLinks["AirPods Pro"]?.imageUrl && (
          <a href={productLinks["AirPods Pro"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["AirPods Pro"].imageUrl} alt="AirPods Pro" className="product-image" />
          </a>
        )}

        <h2>For the Techie Dad:</h2>
        <p>
          <a href={productLinks["Schlage Encode"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Schlage Encode</strong>
          </a>
          {productLinks["Schlage Encode"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Schlage Encode"]?.discountPercent}% Off)</span>
          )}
          : Give Dad the gift of a smart home upgrade with the Schlage Encode smart lock. This sleek and secure lock replaces traditional deadbolts and offers a variety of features, including keyless entry, HomeKit compatibility, and remote access. The Encode is a fantastic way to enhance Dad's home security and add a touch of convenience to his daily life.
        </p>
        {productLinks["Schlage Encode"]?.imageUrl && (
          <a href={productLinks["Schlage Encode"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Schlage Encode"].imageUrl} alt="Schlage Encode" className="product-image" />
          </a>
        )}

        <p>
          <a href={productLinks["Levoit Air Purifier"]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
            <strong className="highlight">Levoit Air Purifier</strong>
          </a>
          {productLinks["Levoit Air Purifier"]?.onSale && (
            <span className="sale-text"> (On Sale Now - {productLinks["Levoit Air Purifier"]?.discountPercent}% Off)</span>
          )}
          : For a techie dad who prioritizes his health, the Levoit Air Purifier is a thoughtful gift. This purifier effectively removes dust, pollen, pet dander, and other airborne pollutants, improving the air quality in your dad's home. Its quiet operation, user-friendly app, and smart features make it a great choice for tech enthusiasts who want to breathe easier.
        </p>
        {productLinks["Levoit Air Purifier"]?.imageUrl && (
          <a href={productLinks["Levoit Air Purifier"]?.url} target="_blank" rel="noopener noreferrer">
            <img src={productLinks["Levoit Air Purifier"].imageUrl} alt="Levoit Air Purifier" className="product-image" />
          </a>
        )}

        <p>No matter what your dad's passions are, you can find a unique and thoughtful gift that will make him happy on Father's Day. From high-end headphones to cutting-edge fitness trackers, there's something for everyone on this list. So start shopping and make this Father's Day one to remember!</p>
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

export default FathersDayGiftIdeas;
