import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import yosemite from 'src/assets/img/webp/yosemite.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "Merrell Moab 2 Vent Mid Hiking Boots": 408,
  "Altra Lone Peak ALL-WTHR Mid Hiking Boots": 397,
  "Canon 5D Mark III": 207,
  "Sony A7iii": 208,
  "JBL Flip": 179,
  "UE Megaboom": 173,
  "Garmin Fenix": 241,
  "Garmin Forerunner": 240,
  "UniFi security camera": 497,
  "Schlage Encode": 534
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const AdventureGifts: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/adventure-gifts"
    },
    "headline": "Outdoor Adventure Essentials for 2024: Gear Up for Your Next Expedition – And Protect Your Home While You're Away",
    "description": "Gear up for your next expedition with the best outdoor adventure essentials of 2024.",
    "image": yosemite, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Outdoor adventure, Tech gadgets",
    "keywords": ["Outdoor adventure", "hiking boots", "cameras", "portable speakers", "fitness trackers", "smart locks", "security cameras"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Outdoor Adventure Essentials for 2024",
      "item": "https://www.buyreddit.com/articles/adventure-gifts"
    }
  ]
}


  return (
    <div className="article-page">
      <Helmet>
        <title>Outdoor Adventure Essentials for 2024</title>
        <meta name="description" content="Gear up for your next expedition with the best outdoor adventure essentials of 2024." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/adventure-gifts" />
        <meta name="keywords" content="Outdoor adventure, hiking boots, cameras, portable speakers, fitness trackers, smart locks, security cameras" />
        <meta property="og:title" content="Outdoor Adventure Essentials for 2024" />
        <meta property="og:description" content="Gear up for your next expedition with the best outdoor adventure essentials of 2024." />
        <meta property="og:image" content={yosemite} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">Outdoor Adventure Essentials for 2024: Gear Up for Your Next Expedition – And Protect Your Home While You're Away</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link> {/* Add link below the title */}
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={yosemite} alt="Adventure Gifts" className="article-image" />
      <div className="article-content">
        <p>The call of the wild is strong, and 2024 promises a year of thrilling outdoor adventures. But before you embark on your next hike or trek, it's essential to ensure your home is safe and secure while you're away. We've compiled a list of outdoor adventure essentials, featuring top-of-the-line products designed to enhance your next expedition, and protect your home while you explore.</p>
        
        {Object.entries(product_dict).map(([productName, productId], index) => (
          <React.Fragment key={productId}>
            {(index === 2 || index === 5 || index === 8) && productLinks[productName]?.imageUrl && (
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer">
                <img src={productLinks[productName].imageUrl} alt={productName} className="product-image" />
              </a>
            )}
            <h2>{index + 1}. {productName}</h2>
            <p>
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productLinks[productName]?.onSale && (
                <span className="sale-text"> (On Sale Now - {productLinks[productName].discountPercent}% Off)</span>
              )}
              : {getDescriptionForProduct(productName)}
            </p>
          </React.Fragment>
        ))}
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

const getDescriptionForProduct = (productName: string): string => {
  switch (productName) {
    case "Merrell Moab 2 Vent Mid Hiking Boots":
      return "Designed for comfort and breathability, these boots are perfect for warm-weather hikes. They offer excellent traction on various surfaces and provide reliable ankle support.";
    case "Altra Lone Peak ALL-WTHR Mid Hiking Boots":
      return "If you prefer a wider toe box for a more natural foot feel, Altra Lone Peak boots are your go-to. Their wide toe box allows for natural foot movement and provides a comfortable fit, even on long treks.";
    case "Canon 5D Mark III":
      return "This high-quality DSLR camera is a perfect choice for capturing stunning photos and videos of your outdoor adventures. Its durable construction, exceptional image quality, and impressive performance will allow you to document every moment.";
    case "Sony A7iii":
      return "If you're looking for a full-frame mirrorless camera with excellent low-light performance, the Sony A7iii is an exceptional choice. It's a great option for capturing those breathtaking sunsets and starlit nights.";
    case "JBL Flip":
      return "Pack your favorite tunes along for the ride with the JBL Flip portable speaker. Its compact size, rugged design, and impressive sound quality make it perfect for outdoor adventures.";
    case "UE Megaboom":
      return "For those who prefer a more immersive sound experience, the UE Megaboom portable speaker delivers. Its 360-degree sound fills the air with rich, clear audio, and its waterproof design makes it perfect for outdoor activities.";
    case "Garmin Fenix":
      return "This rugged smartwatch is a dedicated companion for adventurers of all levels. Its advanced GPS system, heart rate monitor, and durable construction ensure you can track your every move and push your limits. It even includes safety features like emergency SOS, making it a valuable tool for solo adventures.";
    case "Garmin Forerunner":
      return "If you're a dedicated runner, the Garmin Forerunner is a fantastic option. It features excellent GPS tracking, activity monitoring, and long battery life, making it ideal for logging your miles on any terrain.";
    case "UniFi security camera":
      return "This high-quality camera provides exceptional image clarity, a user-friendly interface, and reliable performance. You can keep a watchful eye on your home while you're away on your adventure, and even access recorded footage from your phone.";
    case "Schlage Encode":
      return "This smart lock offers keyless entry, HomeKit compatibility, and reliable performance, letting you control your door access remotely.";
    default:
      return "";
  }
};

export default AdventureGifts;
