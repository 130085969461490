import React, { FunctionComponent } from 'react';
import './PrivacyPolicy.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';

const PrivacyPolicy: FunctionComponent = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last Updated: April 30, 2024</p>
      <h2>1. Introduction</h2>
      <p>This Privacy Policy explains how BuyReddit, a service provided by Subhash Ventures, collects, uses, and shares information about you when you use our website, buyreddit.com. Our platform aggregates and showcases top-rated product recommendations from Reddit, helping you make informed purchasing decisions.</p>
      <h2>2. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> While our service does not require registration, if you contact us directly via our support channels or interact with the website in a way that requires personal information, such as subscribing to newsletters or participating in promotions, we may collect information you provide, like your name and email address.</li>
        <li><strong>Usage Information:</strong> We collect information regarding your interactions with our website, such as the products you view, the features you use, and the time you spend on various parts of our website.</li>
        <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar tracking technologies to track activity on our service and hold certain information, enhancing your browsing experience and allowing us to improve our website.</li>
      </ul>
      <h2>3. Use of Information</h2>
      <p>We use the information we collect primarily to provide and improve our service, personalize your site experience, and communicate with you regarding any services or offers related to BuyReddit.</p>
      <h2>4. Sharing of Information</h2>
      <p>Your personal information is not sold or rented to third parties. We may share information with third-party service providers to the extent necessary for them to perform functions on our behalf, such as site analytics and customer service, under confidentiality agreements.</p>
      <h2>5. Data Security</h2>
      <p>We take reasonable measures, including administrative, technical, and physical safeguards, to protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>
      <h2>6. Changes to This Policy</h2>
      <p>We may update our Privacy Policy periodically to reflect changes to our practices or for other operational, legal, or regulatory reasons. Changes will be effective immediately upon their posting on this page.</p>
      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at support@buyreddit.com.</p>
      <AppFooter />
    </div>
  );
};

export default PrivacyPolicy;
