import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppIntro from 'components/ui/AppIntro';
import AppFooter from 'components/partials/AppFooter';
import './QuitNicotinePage.css';
import quitnicotine from 'src/assets/img/webp/quitnicotine.webp';
import CompactArticleList from 'components/ui/CompactArticleList';

interface Supplement {
  name: string;
  description: string;
}

interface SupplementSection {
  title: "During the Day" | "After Dinner";
  supplements: Supplement[];
}

interface Product {
  product_id: number;
  id: number;
  link: string;
  name: string;
  affiliateurl: string;
  image_url: string;
  price: string;
  mentions: number;
  positive_attributes?: string;
  negative_attributes?: string;
  isCategoryPage: boolean;
  discount_percent?: number;
  on_sale?: boolean;
  original_price?: number;
}

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const QuitNicotinePage: React.FC = () => {
  const [supplementSections, setSupplementSections] = useState<SupplementSection[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const renderSupplementSection = (section: SupplementSection, products: Product[]) => (
    <div key={section.title} className="supplement-section">
      <h3>{section.title}</h3>
      {section.supplements.map((supplement, index) => (
        <div key={index} className="supplement-info">
          <h4>{supplement.name}</h4>
          <p>{supplement.description}</p>
        </div>
      ))}
      <div className="product-grid mobile-product-grid">
        {products.map((product) => (
          <div key={product.product_id} className="product-tile">
            <a href={product.link} target="_blank" rel="noopener noreferrer">
              <div className="product-image-container">
                <img src={product.image_url} alt={product.name} />
              </div>
              <div className="product-info">
                <h3 className="product-name">{product.name}</h3>
                <p className="product-price">${product.price}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    setSupplementSections([
      {
        title: "During the Day",
        supplements: [
          {
            name: "Vitamin C",
            description: "Vitamin C can help reduce nicotine cravings and withdrawal symptoms. It may also aid in repairing damage caused by smoking or vaping to lung tissue and blood vessels. Taking vitamin C can potentially accelerate the removal of nicotine from your body."
          },
          {
            name: "L-Tyrosine",
            description: "This amino acid is a precursor to dopamine, which nicotine typically stimulates. Supplementing with L-Tyrosine may help balance mood and reduce cravings by supporting natural dopamine production without nicotine."
          },
          {
            name: "Rhodiola Rosea",
            description: "As an adaptogenic herb, Rhodiola Rosea can help manage stress and fatigue often associated with nicotine withdrawal. It may improve focus and energy levels naturally, potentially easing the transition away from nicotine's stimulant effects."
          }
        ]
      },
      {
        title: "After Dinner",
        supplements: [
          {
            name: "5-HTP (5-Hydroxytryptophan)",
            description: "5-HTP is a precursor to serotonin, which can help regulate mood and sleep. During nicotine withdrawal, it may help manage irritability and improve sleep quality, addressing common challenges in the quitting process."
          },
          {
            name: "Ashwagandha",
            description: "This adaptogenic herb can help reduce stress and anxiety, common symptoms when quitting nicotine. It may also support better sleep and help manage mood swings associated with nicotine withdrawal."
          },
          {
            name: "Chamomile Tea",
            description: "While not a supplement in the traditional sense, chamomile tea can be a helpful addition to your evening routine. It has calming properties that may ease anxiety and promote better sleep, which is often disrupted during nicotine cessation."
          }
        ]
      }
    ]);

    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const productList = [
          { product_id: 1, id: 1, link: "https://amzn.to/4fE00kh", name: "NOW Foods Vitamin C", affiliateurl: "https://amzn.to/4fE00kh", image_url: "https://m.media-amazon.com/images/I/719kYedj85L._AC_SL1500_.jpg", price: "8.75", mentions: 150, isCategoryPage: false },
          { product_id: 2, id: 2, link: "https://amzn.to/46Hokh2", name: "NOW Foods L-Tyrosine", affiliateurl: "https://amzn.to/46Hokh2", image_url: "https://m.media-amazon.com/images/I/61Nrh8EytHL._AC_SL1000_.jpg", price: "8.96", mentions: 120, isCategoryPage: false },
          { product_id: 3, id: 3, link: "https://amzn.to/3AoB00s", name: "Bronson Rhodiola Rosea", affiliateurl: "https://amzn.to/3AoB00s", image_url: "https://m.media-amazon.com/images/I/71SqnhGpUUL._AC_SL1500_.jpg", price: "9.99", mentions: 110, isCategoryPage: false },
          { product_id: 4, id: 4, link: "https://amzn.to/3AoB3JG", name: "Source Naturals 5-HTP", affiliateurl: "https://amzn.to/3AoB3JG", image_url: "https://m.media-amazon.com/images/I/61gdx2vhdBL._AC_SL1500_.jpg", price: "9.68", mentions: 140, isCategoryPage: false },
          { product_id: 5, id: 5, link: "https://amzn.to/46GLnbY", name: "Organic India Ashwagandha", affiliateurl: "https://amzn.to/46GLnbY", image_url: "https://m.media-amazon.com/images/I/6131QQYIPtL._AC_SL1500_.jpg", price: "19.79", mentions: 130, isCategoryPage: false },
          { product_id: 6, id: 6, link: "https://amzn.to/3X0QW1J", name: "Traditional Medicinals Organic Chamomile Tea", affiliateurl: "https://amzn.to/3X0QW1J", image_url: "https://m.media-amazon.com/images/I/81Q0ZuiRmKL._SL1500_.jpg", price: "29.52", mentions: 100, isCategoryPage: false },
        ];

        setProducts(productList);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error instanceof Error ? error.message : 'An unexpected error occurred');
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  type GroupedProducts = {
    "During the Day": Product[];
    "After Dinner": Product[];
  };

  const groupedProducts: GroupedProducts = {
    "During the Day": products.slice(0, 3),
    "After Dinner": products.slice(3)
  };

  const commentsAnalyzed = (
    <>
      Analyzed <span className="comments-count">{3127}</span> of the most relevant Reddit comments 
      - last updated <span className="comments-count">{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
    </>
  );

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "How to Quit Nicotine in 2024 With Supplements (according to Reddit)",
    "description": "Learn how to quit nicotine in 2024 with advice from Reddit users and recommended supplements.",
    "image": quitnicotine,
    "datePublished": "2024-08-11T00:00:00+00:00",
    "dateModified": "2024-08-11T00:00:00+00:00",
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.buyreddit.com/logo512.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/how-to-quit-nicotine"
    }
  };

  return (
    <div className="quit-nicotine-page">
      <Helmet>
        <title>How to Quit Nicotine in 2024 With Supplements (according to Reddit) | BuyReddit</title>
        <meta name="description" content="Learn how to quit nicotine in 2024 with advice from Reddit users and recommended supplements." />
        <link rel="canonical" href="https://www.buyreddit.com/how-to-quit-nicotine" />
        <meta name="keywords" content="quit nicotine, vaping cessation, smoking withdrawal, supplements for quitting nicotine, Reddit advice, Vitamin C, L-Tyrosine, Rhodiola Rosea, 5-HTP, Ashwagandha, Chamomile Tea" />
        <meta property="og:title" content="How to Quit Nicotine in 2024 With Supplements (Reddit Guide)" />
        <meta property="og:description" content="Explore top-rated supplements recommended by Reddit users to help quit nicotine in 2024." />
        <meta property="og:image" content={quitnicotine} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://www.buyreddit.com/how-to-quit-nicotine" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      
      <AppIntro
        categoryName={<h1>How to Quit Nicotine in 2024 <span className="in-order">(With Supplements)</span></h1>}
        description=""
        backdropUrl={quitnicotine}
        commentsAnalyzed={commentsAnalyzed}
      />

      <h1 style={{ display: 'none' }}>
        Quit Nicotine, Vaping Cessation, Smoking Withdrawal, Supplements for Quitting Nicotine, Reddit Advice on Quitting Nicotine, Vitamin C for Quitting Smoking, L-Tyrosine for Nicotine Withdrawal, Rhodiola Rosea for Stress Management, 5-HTP for Mood Balance, Ashwagandha for Anxiety, Chamomile Tea for Sleep, How to Stop Vaping, Natural Remedies for Quitting Smoking
      </h1>

      <div className="content-wrapper">
        <div className="left-column">
          <h2>The Supplements You Need</h2>
          {supplementSections.map((section, index) => 
            renderSupplementSection(section, groupedProducts[section.title])
          )}
        </div>

        <div className="right-column">
          <h2>Recommended Brands (Best of the Best)</h2>
          {isLoading ? (
            <p>Loading products...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="product-grid desktop-product-grid">
              {Object.entries(groupedProducts).map(([category, categoryProducts]) => (
                <React.Fragment key={category}>
                  <h3 className="product-category-title">{category}</h3>
                  {categoryProducts.map((product) => (
                    <div key={product.product_id} className="product-tile">
                      <a href={product.link} target="_blank" rel="noopener noreferrer">
                        <div className="product-image-container">
                          <img src={product.image_url} alt={product.name} />
                        </div>
                        <div className="product-info">
                          <h3>{product.name}</h3>
                          <p className="product-price">${product.price}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="compact-article-list">
            <CompactArticleList />
          </div>
        </div>
      </div>

      <AppFooter />
    </div>
  );
};

export default QuitNicotinePage;