import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css';
import AppFooter from 'components/partials/AppFooter';
import blogimg from 'src/assets/img/webp/fitnesspic.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal';

const product_dict = {
  "Garmin Forerunner": 240,
  "Garmin Fenix": 241,
  "Samsung Galaxy Watch": 243,
  "Apple Watch Ultra": 244
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const FitnessTrackerBenefits: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/fitness-tracker-benefits"
    },
    "headline": "The Health Benefits of Fitness Trackers",
    "description": "Explore the scientifically-backed benefits of fitness trackers, including health improvements..",
    "image": blogimg,
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit
      }
    },
    "datePublished": "2024-06-15T10:00:00-04:00",
    "dateModified": "2024-06-15T10:00:00-04:00",
    "articleSection": "Health, Productivity, Office Ergonomics",
    "keywords": ["activity tracker", "fitness tracker", "fitness watch", "smartwatch", "productivity", "physical health"]
  };
  
  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.buyreddit.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Articles",
        "item": "https://www.buyreddit.com/articles"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "The Health Benefits of Fitness Trackers",
        "item": "https://www.buyreddit.com/articles/fitness-tracker-benefits"
      }
    ]
  }

  return (
    <div className="article-page">
      <Helmet>
      <title>The Health Benefits of Fitness Trackers</title>
    <meta name="description" content="Discover the benefits of using fitness trackers, including improved health tracking, motivation, and fitness goal achievement." />
    <link rel="canonical" href="https://www.buyreddit.com/articles/fitness-tracker-benefits" />
    <meta name="keywords" content="fitness trackers, health tracking, motivation, fitness goals, wearable technology, exercise" />
    <meta property="og:title" content="The Benefits of Fitness Trackers: A Must-Have in 2024" />
    <meta property="og:description" content="Discover the benefits of using fitness trackers, including improved health tracking, motivation, and fitness goal achievement." />
    <meta property="og:image" content={blogimg} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Health Benefits of Fitness Trackers</h1>
      <h2 className="text-center">Are Fitness Trackers Worth It?</h2>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 24, 2024</p>
      <img src={blogimg} alt="Human using fitness watch" className="article-image2" />
      <div className="article-content underlined-links">
      <div className="article-content">
        <h2>Key Benefits of Fitness Trackers</h2>

        <h3 className='greenback'>1. Increased Health Consciousness:</h3>
        <ul className="highlighted-list">
          <li>Wearing fitness trackers can significantly increase users' health consciousness, making them more aware of their daily activities.</li>
          <li>Users often perceive an improvement in their physical health as they become more mindful of their movements and habits.</li>
        </ul>
        <h3 className='greenback'>2. Motivation:</h3>
        <ul className="highlighted-list">
          <li>Fitness trackers can motivate users to adopt healthier lifestyles by providing feedback on their activity levels.</li>
          <li>The sense of accountability created by these devices encourages users to stay active and make healthier choices.</li>
        </ul>
        <h3 className='greenback'>3. Psychological Well-being:</h3>
        <ul className="highlighted-list">
          <li>Users of fitness trackers report a sense of accomplishment when they achieve their fitness goals, leading to improved psychological well-being.</li>
          <li>These devices can help build communities of like-minded individuals, providing social support and fostering shared health goals.</li>
        </ul>
        <h3 className='greenback'>4. Awareness and Health Management:</h3>
        <ul className="highlighted-list">
          <li>Fitness trackers help users become more aware of their daily activities, including movement, sleep, and nutrition.</li>
          <li>This increased awareness can lead to better overall health management as users make informed decisions based on their tracked data.</li>
        </ul>

        <h2>Popular Fitness Tracker Options</h2>
        <p>If you're considering upgrading your health with a fitness tracker, here are some popular options, with our <a href="https://www.buyreddit.com/categories/fitnesstracker" target="_blank">full list here</a>:</p>

        {Object.entries(productLinks).map(([productName, productInfo]) => (
          <div key={productName}>
            <h3>
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer" className="product-link">
              <strong className="highlight">
                  {productName}
                </strong>              </a>
              {productInfo.onSale && (
                <span className="sale-text">(On Sale Now - {productInfo.discountPercent}% Off)</span>
              )}
            </h3>
            {productInfo.imageUrl && (
              <a href={productInfo.url} target="_blank" rel="noopener noreferrer">
                <img src={productInfo.imageUrl} alt={productName} className="product-image" />
              </a>
            )}
          </div>
        ))}
        <h2>References</h2>
        <ol className="references-list">
  <li>Stiglbauer, B., Weber, S., & Batinic, B. (2019). Does your health really benefit from using a self-tracking device? Evidence from a longitudinal randomized control trial. <em>Computers in Human Behavior</em>, 92, 177-186. <a href="https://doi.org/10.1016/j.chb.2019.01.018" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.chb.2019.01.018</a></li>
  <li>Shin, G., Jarrahi, M. H., Fei, Y., Karami, A., Gafinowitz, N. C. L., Byun, A., & Lu, X. (2019). Wearable activity trackers, accuracy, adoption, acceptance and health impact: A systematic literature review. <em>Journal of Biomedical Informatics</em>, 93, 103153. <a href="https://doi.org/10.1016/j.jbi.2019.103153" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.jbi.2019.103153</a></li>
  <li>Toner, J. (2018). Exploring the dark-side of fitness trackers: Normalization, objectification and the anaesthetisation of human experience. <em>Performance Enhancement & Health</em>, 6(1-2), 40-46. <a href="https://doi.org/10.1016/J.PEH.2018.06.001" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/J.PEH.2018.06.001</a></li>
  <li>Ahuja, N., Ozdalga, E., & Aaronson, A. (2017). Integrating Mobile Fitness Trackers Into the Practice of Medicine. <em>American Journal of Lifestyle Medicine</em>, 11(3), 234-237. <a href="https://doi.org/10.1177/1559827615583643" target="_blank" rel="noopener noreferrer">https://doi.org/10.1177/1559827615583643</a></li>
  <li>Wu, Q., Sum, K., & Nathan-Roberts, D. (2016). How Fitness Trackers Facilitate Health Behavior Change. <em>Proceedings of the Human Factors and Ergonomics Society Annual Meeting</em>, 60(1), 1219-1223. <a href="https://doi.org/10.1177/1541931213601247" target="_blank" rel="noopener noreferrer">https://doi.org/10.1177/1541931213601247</a></li>
  <li>Kiessling, B., & Kennedy-Armbruster, C. (2016). MOVE MORE, SIT LESS, AND BE WELL: Behavioral Aspects of Activity Trackers. <em>ACSM'S Health & Fitness Journal</em>, 20(5), 38-43. <a href="https://doi.org/10.1249/FIT.0000000000000254" target="_blank" rel="noopener noreferrer">https://doi.org/10.1249/FIT.0000000000000254</a></li>
  <li>Vitak, J., Liao, Y., Kumar, P. C., Zimmer, M., & Kritikos, K. (2018). Privacy Attitudes and Data Valuation Among Fitness Tracker Users. In <em>Privacy in the Digital Age: 21st-Century Challenges to the Fourth Amendment</em> (pp. 497-511). Springer, Cham. <a href="https://doi.org/10.1007/978-3-319-78105-1_27" target="_blank" rel="noopener noreferrer">https://doi.org/10.1007/978-3-319-78105-1_27</a></li>
  <li>Evenson, K., Goto, M. M., & Furberg, R. (2015). Systematic review of the validity and reliability of consumer-wearable activity trackers. <em>The International Journal of Behavioral Nutrition and Physical Activity</em>, 12(1), 159. <a href="https://doi.org/10.1186/s12966-015-0314-1" target="_blank" rel="noopener noreferrer">https://doi.org/10.1186/s12966-015-0314-1</a></li>
  <li>Böhm, B., Karwiese, S. D., Böhm, H., & Oberhoffer, R. (2019). Effects of Mobile Health Including Wearable Activity Trackers to Increase Physical Activity Outcomes Among Healthy Children and Adolescents: Systematic Review. <em>JMIR mHealth and uHealth</em>, 7(4), e8298. <a href="https://doi.org/10.2196/MHEALTH.8298" target="_blank" rel="noopener noreferrer">https://doi.org/10.2196/MHEALTH.8298</a></li>
  <li>Abdelhamid, M. (2021). Fitness Tracker Information and Privacy Management: Empirical Study. <em>Journal of Medical Internet Research</em>, 23(1), e23059. <a href="https://doi.org/10.2196/23059" target="_blank" rel="noopener noreferrer">https://doi.org/10.2196/23059</a></li>
</ol>
      </div>
      </div>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
      </div>
      
  );
};

export default FitnessTrackerBenefits;