import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import smartdevices from 'src/assets/img/webp/smartdevices.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "Schlage Encode": 534,
  "Yale Assure": 535,
  "August Smart Lock": 537,
  "Ecobee": 586,
  "Nest": 587,
  "Sonos Arc": 228,
  "Sonos Beam": 229,
  "JBL Bar": 235,
  "Levoit Air Purifier": 543,
  "BlueAir": 544
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const SmartDevices: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/smart-devices"
    },
    "headline": "The Smart Home Revolution: Turn Your House into a Haven of Comfort and Convenience",
    "description": "Explore the latest smart home devices that add convenience and security to your home.",
    "image": smartdevices, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Smart home, Tech gadgets",
    "keywords": ["smart home", "best smart home devices", "home automation", "2024 smart home", "smart locks", "smart thermostats", "soundbars", "air purifiers"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "The Smart Home Revolution: Turn Your House into a Haven of Comfort and Convenience",
      "item": "https://www.buyreddit.com/articles/smart-devices"
    }
  ]
}
  return (
    <div className="article-page">
      <Helmet>
        <title>The Smart Home Revolution: Turn Your House into a Haven of Comfort and Convenience</title>
        <meta name="description" content="Explore the latest smart home devices that add convenience and security to your home." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/smart-devices" />
        <meta name="keywords" content="smart home, best smart home devices, home automation, 2024 smart home, smart locks, smart thermostats, soundbars, air purifiers" />
        <meta property="og:title" content="The Smart Home Revolution: Turn Your House into a Haven of Comfort and Convenience" />
        <meta property="og:description" content="Explore the latest smart home devices that add convenience and security to your home." />
        <meta property="og:image" content={smartdevices} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">The Smart Home Revolution: Turn Your House into a Haven of Comfort and Convenience</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={smartdevices} alt="Smart Home Devices" className="article-image" />
      <div className="article-content">
        <p>Remember the days when "smart home" was just a futuristic concept? Now, it's a reality, and it's transforming the way we live. Smart home technology is no longer just for tech enthusiasts, it's for anyone who wants to make their life easier, more comfortable, and more secure.</p>
        <p>But with so many smart home gadgets available, where do you even begin? We're here to guide you through the smart home revolution, focusing on devices that truly make a difference – ones that enhance your daily life, save you time, and add a touch of luxury to your home.</p>

        {Object.entries(product_dict).map(([productName, productId], index) => (
          <React.Fragment key={productId}>
            {(index === 2 || index === 5 || index === 8) && productLinks[productName]?.imageUrl && (
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer">
                <img src={productLinks[productName].imageUrl} alt={productName} className="product-image" />
              </a>
            )}
            <h2>{index + 1}. {productName}</h2>
            <p>
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productLinks[productName]?.onSale && (
                <span className="sale-text"> (On Sale Now - {productLinks[productName].discountPercent}% Off)</span>
              )}
              : {getDescriptionForProduct(productName)}
            </p>
          </React.Fragment>
        ))}

        <p>With these top tech gadgets, you can create a home that's not just a place to live, but a place where technology works for you, making life easier and more secure.</p>
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

const getDescriptionForProduct = (productName: string): string => {
  switch (productName) {
    case "Schlage Encode":
      return "This smart lock is a popular choice, offering a range of features including keyless entry, HomeKit compatibility, and reliable performance. It seamlessly integrates with your smart home setup, letting you control access remotely and even track activity.";
    case "Yale Assure":
      return "This smart lock is known for its robust build quality and a variety of connectivity options. It offers keyless entry through a touchscreen keypad, as well as remote access and integration with your smart home system.";
    case "August Smart Lock":
      return "This smart lock is perfect for those who want an easy and discreet way to upgrade their security. It seamlessly integrates with existing deadbolts, offering keyless entry and remote access through an intuitive app.";
    case "Ecobee":
      return "This thermostat is a favorite among smart home enthusiasts, offering a range of advanced features including remote sensors, HomeKit compatibility, and extensive integrations. Its remote sensors allow you to customize the temperature in different rooms, ensuring everyone is comfortable.";
    case "Nest":
      return "This thermostat is known for its sleek design, learning capabilities, and Home/Away assist. It automatically adjusts the temperature based on your schedule and your presence, ensuring a comfortable and energy-efficient home.";
    case "Sonos Arc":
      return "This soundbar is known for its excellent sound quality and seamless integration with smart home systems. It offers a truly immersive listening experience with its advanced audio processing and Dolby Atmos support.";
    case "Sonos Beam":
      return "This compact soundbar is a great choice for smaller rooms. It delivers impressive sound quality, with a focus on clear dialogue for TV shows and movies.";
    case "JBL Bar":
      return "This soundbar offers a powerful and dynamic sound experience, perfect for those who want a true home theater experience. It's also equipped with a variety of audio codecs for a versatile listening experience.";
    case "Levoit Air Purifier":
      return "This purifier effectively removes dust, allergens, and odors, and offers a range of features like app control and smart features. It's a great choice for those who want a reliable and efficient air purifier.";
    case "BlueAir":
      return "This purifier is known for its quiet operation and stylish design. It effectively filters out pollutants with its advanced HEPA and activated carbon filtration system.";
    default:
      return "";
  }
};

export default SmartDevices;
