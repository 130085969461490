import React, { FunctionComponent } from 'react';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { Link } from 'react-router-dom';

import buyreddit from 'src/assets/img/webp/buyreddit.webp';

import './AppSocials.sass';

const b = cn('Socials');

interface IProps {
  className?: string;
}

const AppSocials: FunctionComponent<IProps> = ({ className }) => {
  return (
    <div className={classnames(b('Socials'), className)}>
      <ul className={b('List')}>
        <li className={b('Item')}>
          {/* Wrap the img in a standard anchor tag to link to the homepage */}
          <Link to="/" aria-label="Home">
            <img src={buyreddit} alt="BuyReddit Logo" width={276} height={276}           fetchPriority="high"
              loading="eager"               decoding="async" style={{
                contentVisibility: 'auto',
                objectFit: 'contain',
                maxWidth: '100%',
                height: 'auto',
              }}
              />
          </Link>
          </li>
      </ul>
    </div>
  );
};

export default AppSocials;
