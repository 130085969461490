import React, { FunctionComponent, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { useLocation, Link } from 'react-router-dom';

import AppSocials from 'components/ui/AppSocials';
import categoryData from '../../../pages/CategoryPage/categoryData';  // Adjust the path as necessary

import './TopBar.sass';

const b = cn('TopBar');

interface IProps {
  className?: string;
}

const TopBar: FunctionComponent<IProps> = ({ className }) => {
  const location = useLocation();
  const [showCategoryName, setShowCategoryName] = useState(false);
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'categories') {
      if (pathParts.length === 3 && pathParts[2] && categoryData[pathParts[2]]) {
        const categoryKey = pathParts[2];
        let name = categoryData[categoryKey].name;

        if (name === 'Electric Toothbrush') {
          name = 'Electric Toothbrushes';
        } else if (!name.toLowerCase().endsWith('s')) {
          name += 's';
        }

        setCategoryName(`Top ${name}`);
      } else if (pathParts.length === 2) {
        setCategoryName('All Products');
      } else {
        setCategoryName('');
      }
    } else if (location.pathname === '/popular') {
      setCategoryName('Ultimate List');  // Set custom text for /popular page
    } else if (location.pathname === '/sale') {
      setCategoryName('Deals');  // Set custom text for /sale page
    } else if (location.pathname === '/articles/fathers-day-gift-ideas') {
      setCategoryName('Father\'s Day 2024');  // Set custom text for /sale page
    } else if (location.pathname === '/articles/summer-must-haves') {
      setCategoryName('Summer Must Haves');  // Set custom text for /sale page
    } else if (location.pathname === '/articles/birthday-gift-ideas') {
      setCategoryName('Birthday Gifts');  // Set custom text for /sale page
    } else if (location.pathname === '/articles/graduation-gifts') {
      setCategoryName('Graduation Gifts');  // Set custom text for /sale page
    } else if (location.pathname === '/articles/pet-gifts') {
      setCategoryName('Pet Gifts');  // Set custom text for /sale page
    } else if (location.pathname === '/articles/adventure-gifts') {
      setCategoryName('Adventure Gifts');  // Set custom text for /sale page
    } 
    else if (location.pathname === '/articles/remote-work-must-haves') {
    setCategoryName('Remote Work Must Haves');  // Set custom text for /sale page
  }    else if (location.pathname === '/articles/smart-devices') {
    setCategoryName('Smart Devices');  // Set custom text for /sale page
  }     else if (location.pathname === '/articles/kitchen-must-haves') {
    setCategoryName('Kitchen Must Haves');  // Set custom text for /sale page
  }  else if (location.pathname === '/articles') {
    setCategoryName('Articles');  // Set custom text for /sale page
  }  else if (location.pathname === '/articles/under-25-dollars-gifts') {
      setCategoryName('Under $25 Gifts');  // Set custom text for /sale page
  }  else if (location.pathname === '/articles/under-50-dollars-gifts') {
    setCategoryName('Under $50 Gifts');  // Set custom text for /sale page
}   else if (location.pathname === '/articles/under-100-dollars-gifts') {
  setCategoryName('Under $100 Gifts');  // Set custom text for /sale page
}     else if (location.pathname === '/articles/under-500-dollars-gifts') {
  setCategoryName('Under $500 Gifts');  // Set custom text for /sale page
} else {
      setCategoryName('');
    }
  }, [location]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowCategoryName(true);
      } else {
        setShowCategoryName(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={classnames(b(), className)}>
      <div className={b('Logo')}>
        <AppSocials />
      </div>
      <div className={b('Links')}>
        {showCategoryName && (
          <h1 onClick={scrollToTop} style={{ cursor: 'pointer' }}>{categoryName}</h1>
        )}
        {location.pathname === '/' && (
          <div className={b('Links')}>
            <Link to="/categories">All Products</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
