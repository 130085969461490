import { Action } from 'redux';

export interface IFavoriteMovie {

}
export interface IFavoriteMoviePartial {
  title: string;
  poster_path: string;
  id: number;
  vote_average: number;
  // Make other properties optional or leave them out if creating a new interface
}
export interface IFavoritesState {
  favoriteMovies: IFavoriteMovie[];
}

export enum ActionTypes {
  SAVE_FAVORITE_MOVIE = '[favorite] save favorite movie',
  DELETE_FAVORITE_MOVIE = '[favorite] delete favorite movie',
}

export interface ISaveFavoriteMovieActions extends Action {
  type: ActionTypes.SAVE_FAVORITE_MOVIE;
  payload: IFavoriteMovie;
}

export interface IDeleteFavoriteMovieActions extends Action {
  type: ActionTypes.DELETE_FAVORITE_MOVIE;
  payload: number;
}

export type IFavoriteMovieActions = ISaveFavoriteMovieActions | IDeleteFavoriteMovieActions;
