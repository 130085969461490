import React, { useState } from 'react';
import Modal from 'react-modal';
import './EmailModal.css'; // Ensure this file exists and contains appropriate styling

Modal.setAppElement('#root'); // Make sure to bind modal to your app element (this is for accessibility reasons)

const EmailModal = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); // Clear the error message when user starts typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Frontend email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email address');
      return;
    }

    const userAgent = navigator.userAgent; // Get the user agent

    try {
      const response = await fetch('/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, userAgent }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Email submitted:', result);
      setIsSubmitted(true); // Set the submission status to true
    } catch (error) {
      console.error('Error submitting email:', error);
      setError('Failed to submit email. Please try again.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Get Notified of the Best Deals"
      className="email-modal"
      overlayClassName="email-modal-overlay"
      shouldCloseOnOverlayClick={true} // This prop will allow closing the modal by clicking outside
    >
      <button className="close-button" onClick={onRequestClose}>&times;</button>
      {!isSubmitted ? (
        <>
          <h2>Get Notified of the Best Deals <br/> on the Best Products</h2>
          <p className="no-spam-text">We don't spam!</p>
          <form onSubmit={handleSubmit}>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </label>
            {error && <p className="error-text">{error}</p>}
            <button type="submit">Submit</button>
          </form>
        </>
      ) : (
        <>
          <h2>Success!</h2>
          <p className="success-text">You're signed up to save money on the best products.</p>
        </>
      )}
    </Modal>
  );
};

export default EmailModal;
