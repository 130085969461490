import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppIntro from 'components/ui/AppIntro';
import AppFooter from 'components/partials/AppFooter';
import './QuitWeedPage.css';
import quitweed from 'src/assets/img/webp/quit-weed-backdrop.webp';
import CompactArticleList from 'components/ui/CompactArticleList';

interface Supplement {
  name: string;
  description: string;
}

interface SupplementSection {
  title: "During the Day" | "After Dinner";
  supplements: Supplement[];
}

interface Product {
  product_id: number;
  id: number;
  link: string;
  name: string;
  affiliateurl: string;
  image_url: string;
  price: string;
  mentions: number;
  positive_attributes?: string;
  negative_attributes?: string;
  isCategoryPage: boolean;
  discount_percent?: number;
  on_sale?: boolean;
  original_price?: number;
}

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const QuitWeedPage: React.FC = () => {
  const [supplementSections, setSupplementSections] = useState<SupplementSection[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const renderSupplementSection = (section: SupplementSection, products: Product[]) => (
    <div key={section.title} className="supplement-section">
      <h3>{section.title}</h3>
      {section.supplements.map((supplement, index) => (
        <div key={index} className="supplement-info">
          <h4>{supplement.name}</h4>
          <p>{supplement.description}</p>
        </div>
      ))}
      <div className="product-grid mobile-product-grid">
        {products.map((product) => (
          <div key={product.product_id} className="product-tile">
            <a href={product.link} target="_blank" rel="noopener noreferrer">
              <div className="product-image-container">
                <img src={product.image_url} alt={product.name} />
              </div>
              <div className="product-info">
                <h3 className="product-name">{product.name}</h3>
                <p className="product-price">${product.price}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
  useEffect(() => {
    setSupplementSections([
      {
        title: "During the Day",
        supplements: [
          {
            name: "NAC (N-Acetyl Cysteine)",
            description: "NAC helps to regulate glutamate levels in the brain, potentially reducing cravings and compulsive behaviors associated with cannabis use. It may also help to restore balance to the brain's reward system, making it easier to resist the urge to use marijuana."
          },
          {
            name: "L-Theanine",
            description: "Found naturally in green tea, L-Theanine promotes relaxation without sedation. It can help manage anxiety and stress often experienced during cannabis withdrawal, potentially improving focus and sleep quality without the 'high' associated with marijuana use."
          },
          {
            name: "Omega-3 Fatty Acids",
            description: "Essential for brain health, Omega-3s may help reduce inflammation and support neurotransmitter function. During cannabis withdrawal, they can potentially ease mood swings, improve cognitive function, and support overall mental well-being."
          }
        ]
      },
      {
        title: "After Dinner",
        supplements: [
          {
            name: "Melatonin",
            description: "As a natural sleep aid, melatonin can help regulate sleep patterns often disrupted during cannabis withdrawal. It may assist in achieving more restful sleep without the dependence on marijuana, potentially easing insomnia symptoms common in early abstinence."
          },
          {
            name: "Magnesium Citrate",
            description: "Magnesium plays a crucial role in regulating the nervous system and may help reduce anxiety and promote relaxation. The citrate form is well-absorbed and may aid in improving sleep quality, reducing muscle tension, and supporting overall mood balance during the quitting process."
          },
          {
            name: "Dandelion Root",
            description: "Often used as a natural detoxifier, dandelion root may support liver function and aid in the elimination of toxins from the body. During cannabis cessation, it could potentially help manage bloating and water retention, supporting the body's natural detoxification processes."
          }
        ]
      }
    ]);

    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const omega3ProductId = 689; // Assuming this is the correct ID for Omega-3
        const response = await fetch(`${API_URL}/api/product/${omega3ProductId}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        
        const productList = [
          { product_id: 1, id: 1, link: "https://www.amazon.com/Life-Extension-N-Acetyl-L-Cysteine-Vegetarian-Capsules/dp/B008ML8D4O?crid=D4K4Z60V32BU&dib=eyJ2IjoiMSJ9.t70WHldCNgSR1myBY-4XuIonhvNRFGzboBT3ScBvJNb8X7hkKsQ1DUUspEl0hiEpS8DE2KamyVV8KFjPk1G55BwCdeAFcpVoS9Ct-Lteg-AbELUzNIAxcxYl_swSqq2jE6CQl9NZMYxxXiNl5--dv8KjRoY6wKRHyBJUO4I68pZ7wfM4aLw4i_0StAph-uTl4U_NpGzgVp5hm3AWtciQ0NuHcemg4ryzqSZA7Qt7UV8bjx9hG0zCG1yjHsRyFuglV_f2IkY07zxyt3BwAJWd0xEESYdXXpmtsKMKC-hcFQY.rhGRyWmkCQqVqdIhH3P6zA0tBTv8F1_5jNinbJJfoy0&dib_tag=se&keywords=nac&qid=1723406315&rdc=1&sprefix=nac%2Caps%2C349&sr=8-6&linkCode=ll1&tag=buyreddit00-20&linkId=65d61f9b4fdf33502bd0f0b8380a7ad7&language=en_US&ref_=as_li_ss_tl", name: "Life Extensions NAC", affiliateurl: "https://www.amazon.com/Life-Extension-N-Acetyl-L-Cysteine-Vegetarian-Capsules/dp/B008ML8D4O?crid=D4K4Z60V32BU&dib=eyJ2IjoiMSJ9.t70WHldCNgSR1myBY-4XuIonhvNRFGzboBT3ScBvJNb8X7hkKsQ1DUUspEl0hiEpS8DE2KamyVV8KFjPk1G55BwCdeAFcpVoS9Ct-Lteg-AbELUzNIAxcxYl_swSqq2jE6CQl9NZMYxxXiNl5--dv8KjRoY6wKRHyBJUO4I68pZ7wfM4aLw4i_0StAph-uTl4U_NpGzgVp5hm3AWtciQ0NuHcemg4ryzqSZA7Qt7UV8bjx9hG0zCG1yjHsRyFuglV_f2IkY07zxyt3BwAJWd0xEESYdXXpmtsKMKC-hcFQY.rhGRyWmkCQqVqdIhH3P6zA0tBTv8F1_5jNinbJJfoy0&dib_tag=se&keywords=nac&qid=1723406315&rdc=1&sprefix=nac%2Caps%2C349&sr=8-6&linkCode=ll1&tag=buyreddit00-20&linkId=65d61f9b4fdf33502bd0f0b8380a7ad7&language=en_US&ref_=as_li_ss_tl", image_url: "https://m.media-amazon.com/images/I/71zNn-t5dgL._AC_SL1500_.jpg", price: "11.40", mentions: 150, isCategoryPage: false },
          { product_id: 2, id: 2, link: "https://amzn.to/3X0LqfA", name: "Sports Research L-Theanine", affiliateurl: "https://amzn.to/3X0LqfA", image_url: "https://m.media-amazon.com/images/I/51JZbaKAsfL._AC_SL1200_.jpg", price: "17.95", mentions: 120, isCategoryPage: false },
          { product_id: 3, id: 3, link: "https://www.amazon.com/Nordic-Naturals-Ultimate-Omega-SoftGels/dp/B0739KKHWL?dib=eyJ2IjoiMSJ9.1DRIQCOvIcbi3QKVTEHUS66sXFYSc9SWfvGyc-Hh9YZyJsBeRPbg0Pc3AVYQBmizSVAOY2_-N0CCsYz8Etz7WHZrFko-cWUwUr7vksyv6Mb_3AFByCdC8Q1zV8HldBnTavLhHyQHp13oTR7_d0risrPw9Va7IAigZ-U574p3Jsbu3BoFq4bF5xx3r5P2UCYvKTpNdOlmfjcci-xYL08jxCxb3kIeirZvf9sdm5pg_8hViGMgSl6a7OSmHMg1kcYDy9T_jq2u21sBqYISmW_s7Aajb2r8yQ4_U0RniDVpqzw.ajiMJDf6H3zz0at3wT8AqmiijO39VlxKTovgn5kmstE&dib_tag=se&keywords=Nordic%2BNaturals&qid=1722892193&sr=8-5&th=1&linkCode=ll1&tag=buyreddit00-20&linkId=03ba6639f670fb11e9ef90c46fb78679&language=en_US&ref_=as_li_ss_tl", name: "Nordic Naturals Omega-3", affiliateurl: "https://amzn.to/3X2wPiz", image_url: "https://m.media-amazon.com/images/I/61NIfEDqazL._AC_SL1000_.jpg", price: "31.43", mentions: 150, isCategoryPage: false },
          { product_id: 4, id: 4, link: "https://amzn.to/3YCSxf9", name: "Nature Made Melatonin", affiliateurl: "https://amzn.to/3YCSxf9", image_url: "https://m.media-amazon.com/images/I/71wi6HM1GuL._AC_SL1500_.jpg", price: "9.29", mentions: 180, isCategoryPage: false },
          { product_id: 5, id: 5, link: "https://amzn.to/46YGfQJ", name: "Nature Made Magnesium Citrate", affiliateurl: "https://amzn.to/46YGfQJ", image_url: "https://m.media-amazon.com/images/I/71kSrWxb8pL._AC_SL1500_.jpg", price: "8.35", mentions: 140, isCategoryPage: false },
          { product_id: 6, id: 6, link: "https://amzn.to/3yAJWiv", name: "Nature's Way Dandelion Root", affiliateurl: "https://amzn.to/3yAJWiv", image_url: "https://m.media-amazon.com/images/I/71R+OOTbNiL._AC_SL1500_.jpg", price: "7.42", mentions: 100, isCategoryPage: false },
        ];

        setProducts(productList);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error instanceof Error ? error.message : 'An unexpected error occurred');
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);
  type GroupedProducts = {
    "During the Day": Product[];
    "After Dinner": Product[];
  };
  const groupedProducts: GroupedProducts = {
    "During the Day": products.slice(0, 3),
    "After Dinner": products.slice(3)
  };
  const commentsAnalyzed = (
    <>
      Analyzed <span className="comments-count">{2856}</span> of the most relevant Reddit comments 
      - last updated <span className="comments-count">{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
    </>
  );

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "How to Quit Weed in 2024 With Supplements (according to Reddit)",
    "description": "Learn how to quit weed in 2024 with advice from Reddit users and recommended supplements.",
    "image": quitweed,
    "datePublished": "2024-08-11T00:00:00+00:00",
    "dateModified": "2024-08-11T00:00:00+00:00",
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.buyreddit.com/logo512.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/how-to-quit-weed"
    }
  };
  return (
    <div className="quit-weed-page">
    <Helmet>
  <title>How to Quit Weed in 2024 With Supplements (according to Reddit) | BuyReddit</title>
  <meta name="description" content="Learn how to quit weed in 2024 with advice from Reddit users and recommended supplements." />
  <link rel="canonical" href="https://www.buyreddit.com/how-to-quit-weed" />
  <meta name="keywords" content="quit weed, cannabis cessation, marijuana withdrawal, supplements for quitting weed, Reddit advice, NAC, L-Theanine, Omega-3, Melatonin, Magnesium Citrate, Dandelion Root" />
  <meta property="og:title" content="How to Quit Weed in 2024 With Supplements (Reddit Guide)" />
  <meta property="og:description" content="Explore top-rated supplements recommended by Reddit users to help quit weed in 2024." />
  <meta property="og:image" content={quitweed} />
  <meta property="og:type" content="article" />
  <meta property="og:url" content="https://www.buyreddit.com/how-to-quit-weed" />
  <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
</Helmet>
      
      <AppIntro
        categoryName={<h1>How to Quit Weed in 2024 <span className="in-order">(With Supplements)</span></h1>}
        description=""
        backdropUrl={quitweed}
        commentsAnalyzed={commentsAnalyzed}
      />
<h1 style={{ display: 'none' }}>
  Quit Weed, Cannabis Cessation, Marijuana Withdrawal, Supplements for Quitting Weed, Reddit Advice on Quitting Weed, NAC for Quitting Weed, L-Theanine for Cannabis Withdrawal, Omega-3 for Quitting Marijuana, Melatonin for Sleep During Withdrawal, Magnesium Citrate for Anxiety, Dandelion Root for Detox, How to Stop Smoking Weed, Natural Remedies for Quitting Cannabis
</h1>
      <div className="content-wrapper">
        <div className="left-column">
          <h2>The Supplements You Need</h2>
          {supplementSections.map((section, index) => 
            renderSupplementSection(section, groupedProducts[section.title])
          )}
        </div>

        <div className="right-column">
          <h2>Recommended Brands (Best of the Best)</h2>
          {isLoading ? (
            <p>Loading products...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="product-grid desktop-product-grid">
              {Object.entries(groupedProducts).map(([category, categoryProducts]) => (
                <React.Fragment key={category}>
                  <h3 className="product-category-title">{category}</h3>
                  {categoryProducts.map((product) => (
                    <div key={product.product_id} className="product-tile">
                      <a href={product.link} target="_blank" rel="noopener noreferrer">
                        <div className="product-image-container">
                          <img src={product.image_url} alt={product.name} />
                        </div>
                        <div className="product-info">
                          <h3>{product.name}</h3>
                          <p className="product-price">${product.price}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="compact-article-list">
            <CompactArticleList />
          </div>
        </div>
      </div>

      <AppFooter />
    </div>
  );
};
export default QuitWeedPage;