import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import graduation from 'src/assets/img/webp/graduation.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "Sennheiser HD6XX": 135,
  "Vitamix Blender": 325,
  "DJI Mini 3": 581,
  "JBL Xtreme": 168,
  "Secretlab Titan": 438,
  "HomePod Mini": 277,
  "Garmin Forerunner": 240,
  "AirPods Pro": 510,
  "Sony A7iii": 208,
  "LG 27GP850-B": 147,
  "Logitech MX Master 3": 566,
  "Sonos Beam": 229,
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const GraduationGifts: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/graduation-gifts"
    },
    "headline": "Top Gifts for Graduation 2024: Celebrate a New Chapter with Thoughtful Presents",
    "description": "Discover the best gifts for graduation 2024, from tech gadgets to stylish accessories.",
    "image": graduation, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Graduation gifts, shopping",
    "keywords": ["Graduation gifts", "tech gifts", "stylish gifts", "practical gifts", "music gifts", "fitness gifts", "home gadgets"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Top Gifts for Graduation in 2024",
      "item": "https://www.buyreddit.com/articles/graduation-gifts"
    }
  ]
}
  return (
    <div className="article-page">
      <Helmet>
        <title>Top Gifts for Graduation 2024: Celebrate a New Chapter with Thoughtful Presents</title>
        <meta name="description" content="Discover the best gifts for graduation 2024, from tech gadgets to stylish accessories." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/graduation-gifts" />
        <meta name="keywords" content="Graduation gifts, tech gifts, stylish gifts, practical gifts, music gifts, fitness gifts, home gadgets" />
        <meta property="og:title" content="Top Gifts for Graduation 2024: Celebrate a New Chapter with Thoughtful Presents" />
        <meta property="og:description" content="Discover the best gifts for graduation 2024, from tech gadgets to stylish accessories." />
        <meta property="og:image" content={graduation} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">Top Gifts for Graduation 2024: Celebrate a New Chapter with Thoughtful Presents</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link> {/* Add link below the title */}
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={graduation} alt="A Picture of students at a graduation" className="article-image" />
      <div className="article-content">
        <p>Graduation is a time for celebration, a momentous occasion that marks the beginning of a new chapter in life. Whether it's a high school diploma or a college degree, it deserves a gift that's both meaningful and practical. We've compiled a list of top gifts for graduation 2024, catering to a variety of interests and needs, all designed to help the graduate soar to new heights.</p>
        
        {Object.entries(product_dict).map(([productName, productId], index) => (
          <React.Fragment key={productId}>
            {(index === 1 || index === 4 || index === 9) && productLinks[productName]?.imageUrl && (
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer">
                <img src={productLinks[productName].imageUrl} alt="a picture of a {productName}" className="product-image" />
              </a>
            )}
            <h2>{index + 1}. {productName}</h2>
            <p>
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productLinks[productName]?.onSale && (
                <span className="sale-text"> (On Sale Now - {productLinks[productName].discountPercent}% Off)</span>
              )}
              : {getDescriptionForProduct(productName)}
            </p>
          </React.Fragment>
        ))}
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

const getDescriptionForProduct = (productName: string): string => {
  switch (productName) {
    case "Sennheiser HD6XX":
      return "These headphones are a true audiophile's dream, delivering a rich, detailed, and natural sound experience. Their wide soundstage and exceptional sound quality make them ideal for enjoying all genres of music.";
    case "Vitamix Blender":
      return "A powerful blender like the Vitamix is a great tool for creating delicious smoothies, soups, sauces, and more. It's a valuable addition to any kitchen, especially for those who are starting to explore their culinary skills.";
    case "DJI Mini 3":
      return "This compact and lightweight drone is perfect for capturing breathtaking aerial footage of new adventures. Its portability and user-friendly features make it a great choice for those who love to explore and document their travels.";
    case "JBL Xtreme":
      return "This powerful, waterproof Bluetooth speaker is perfect for parties and outdoor gatherings. Its long battery life and rugged design mean the music can keep going all night long.";
    case "Secretlab Titan":
      return "This high-quality gaming chair is designed for comfort and support, perfect for long hours of studying or working. It's also stylish and comes in a range of colors and materials to match any aesthetic.";
    case "HomePod Mini":
      return "This smart speaker offers incredible sound quality for its size, making it perfect for filling a room with rich audio. It also integrates seamlessly with Apple devices, making it easy to control and enjoy music, podcasts, and more.";
    case "Garmin Forerunner":
      return "This fitness tracker is a dedicated running companion, featuring an advanced GPS system that accurately tracks your distance, pace, and time. Its built-in heart rate monitor lets you monitor your workout intensity, while its long battery life ensures that you can track multiple runs without needing to charge.";
    case "AirPods Pro":
      return "These earbuds offer excellent noise cancellation and transparency mode, ideal for focusing on studying, working, or enjoying music without distractions. They're also great for calls and are quite durable, making them a great choice for the busy graduate.";
    case "Sony A7iii":
      return "This full-frame mirrorless camera is known for its impressive low-light performance, making it ideal for capturing memories in a variety of settings. It also offers high-quality video recording, making it a versatile option for those interested in video content creation.";
    case "LG 27GP850-B":
      return "This high-resolution, high-refresh-rate monitor is a great choice for students who need a display that can handle demanding tasks, from coding to video editing. It will make their work and leisure time a lot more enjoyable.";
    case "Logitech MX Master 3":
      return "For students who want a mouse that can keep up with their productivity, the Logitech MX Master 3 offers ergonomic comfort, customizable buttons, and a precise sensor.";
    case "Sonos Beam":
      return "This compact soundbar offers excellent sound quality and seamless integration with smart home devices. It's a great gift for the graduate who wants to create a cozy and immersive home entertainment experience.";
    default:
      return "";
  }
};

export default GraduationGifts;
