import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ArticlePage.css'; // Ensure the CSS file is imported
import AppFooter from 'components/partials/AppFooter';
import kitchen from 'src/assets/img/webp/kitchen.webp';
import { Link } from 'react-router-dom';
import buyreddit from 'src/assets/img/webp/buyreddit.webp';
import EmailModal from '../SalePage/EmailModal'; // Ensure the correct import path

const product_dict = {
  "Cuisinart TOA-70": 294,
  "Cosori": 303,
  "Instant Vortex": 302,
  "Vitamix Blender": 325,
  "Ninja Blender": 332,
  "Nutribullet": 337,
  "Moccamaster": 259,
  "Chemex": 263,
  "Aeropress": 260
};

const API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_WEBSITE_URL;

const KitchensBest: React.FC = () => {
  const [productLinks, setProductLinks] = useState<{ [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductLinks = async () => {
      const links: { [key: string]: { url: string, onSale: boolean, discountPercent: number, imageUrl: string } } = {};
      for (const [productName, productId] of Object.entries(product_dict)) {
        try {
          const response = await fetch(`${API_URL}/api/product/${productId}`);
          if (response.ok) {
            const product = await response.json();
            links[productName] = {
              url: product.affiliateurl,
              onSale: product.on_sale,
              discountPercent: Math.round(product.discount_percent),
              imageUrl: product.image_url // Include image URL
            };
          } else {
            console.error(`Failed to fetch product ${productName}: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching product ${productName}:`, error);
        }
      }
      setProductLinks(links);
    };

    fetchProductLinks();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.buyreddit.com/articles/kitchen-must-haves"
    },
    "headline": "Your Kitchen Arsenal: The Ultimate Guide to Must-Have Gadgets for Culinary Conquerors",
    "description": "Discover the best kitchen gadgets that make your cooking journey a breeze and a source of endless culinary creativity.",
    "image": kitchen, // Ensure this URL points to your blog image
    "author": {
      "@type": "Organization",
      "name": "BuyReddit"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BuyReddit",
      "logo": {
        "@type": "ImageObject",
        "url": buyreddit // Ensure this URL points to your logo image
      }
    },
    "datePublished": "2024-06-12T12:10:00-04:00", // Adjust the date as needed
    "dateModified": "2024-06-12T11:47:19-04:00", // Adjust the date as needed
    "articleSection": "Lifestyle, Kitchen gadgets, shopping",
    "keywords": ["kitchen gadgets", "best kitchen appliances", "culinary tools", "kitchen must haves", "air fryers", "blenders", "coffee makers"],
    "itemListElement": Object.keys(product_dict).map((productName, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": productLinks[productName]?.url,
      "name": productName,
      "image": productLinks[productName]?.imageUrl
    }))
  };
  
const breadcrumbData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.buyreddit.com"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Articles",
      "item": "https://www.buyreddit.com/articles"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Your Kitchen Arsenal: The Ultimate Guide",
      "item": "https://www.buyreddit.com/articles/kitchen-must-haves"
    }
  ]
}

  return (
    <div className="article-page">
      <Helmet>
        <title>Your Kitchen Arsenal: The Ultimate Guide to Must-Have Gadgets for Culinary Conquerors</title>
        <meta name="description" content="Discover the best kitchen gadgets that make your cooking journey a breeze and a source of endless culinary creativity." />
        <link rel="canonical" href="https://www.buyreddit.com/articles/kitchen-must-haves" />
        <meta name="keywords" content="kitchen gadgets, best kitchen appliances, culinary tools, kitchen must haves, air fryers, blenders, coffee makers" />
        <meta property="og:title" content="Your Kitchen Arsenal: The Ultimate Guide to Must-Have Gadgets for Culinary Conquerors" />
        <meta property="og:description" content="Discover the best kitchen gadgets that make your cooking journey a breeze and a source of endless culinary creativity." />
        <meta property="og:image" content={kitchen} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbData)}</script>
      </Helmet>
      <h1 className="article-title">Your Kitchen Arsenal: The Ultimate Guide to Must-Have Gadgets for Culinary Conquerors</h1>
      <Link to="/articles" className="see-all-link">See All Articles</Link>
      <p className="last-updated">Last updated: June 8, 2024</p>
      <img src={kitchen} alt="Kitchen Best Gadgets" className="article-image" />
      <div className="article-content">
        <p>The kitchen is the heart of the home, and for many of us, it's also a place of creative expression. We cook up memories, experiment with flavors, and nourish ourselves and our loved ones. But to truly unleash your culinary potential, you need the right tools – a kitchen arsenal that's ready for any challenge.</p>
        <p>We're diving into the world of kitchen gadgets, exploring the best appliances and tools to make your cooking journey a breeze, a delight, and a source of endless culinary creativity.</p>

        {Object.entries(product_dict).map(([productName, productId], index) => (
          <React.Fragment key={productId}>
            {(index === 2 || index === 5 || index === 8) && productLinks[productName]?.imageUrl && (
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer">
                <img src={productLinks[productName].imageUrl} alt={productName} className="product-image" />
              </a>
            )}
            <h2>{index + 1}. {productName}</h2>
            <p>
              <a href={productLinks[productName]?.url} target="_blank" rel="noopener noreferrer" className="product-link">
                <strong className="highlight">
                  {productName}
                </strong>
              </a>
              {productLinks[productName]?.onSale && (
                <span className="sale-text"> (On Sale Now - {productLinks[productName].discountPercent}% Off)</span>
              )}
              : {getDescriptionForProduct(productName)}
            </p>
          </React.Fragment>
        ))}

        <p>This journey into the world of kitchen gadgets is just the beginning. The possibilities are endless, so embrace the culinary adventure!</p>
      </div>
      <button className="notification-button" onClick={handleOpenModal}>Get Notified of the Latest Top Products</button>
      <AppFooter />
      <EmailModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

const getDescriptionForProduct = (productName: string): string => {
  switch (productName) {
    case "Cuisinart TOA-70":
      return "This air fryer oven is a multi-functional marvel, combining the best of air frying, baking, roasting, and grilling. Imagine crispy veggies, perfectly roasted chicken, and even juicy steaks, all cooked to perfection in one appliance.";
    case "Cosori":
      return "This air fryer is a popular choice, offering a blend of performance and affordability. Its digital controls and preset functions make it easy to use, and its square basket lets you cook more food at once.";
    case "Instant Vortex":
      return "This air fryer oven is known for its spacious capacity, perfect for cooking for families or larger groups. It also features a variety of cooking functions, including air frying, baking, roasting, and reheating.";
    case "Vitamix Blender":
      return "This blender is the gold standard, known for its powerful motor and ability to blend even the toughest ingredients into a smooth, creamy consistency. It's a true investment in your culinary creativity.";
    case "Ninja Blender":
      return "This blender is a fantastic option, offering a great balance of performance and affordability. Its powerful blending capabilities and multiple speed settings make it perfect for a range of tasks.";
    case "Nutribullet":
      return "This compact blender is designed for quick and easy blending. It's perfect for single servings, making it ideal for smoothies and other quick meals.";
    case "Moccamaster":
      return "This coffee maker is a favorite among coffee enthusiasts, delivering consistently delicious coffee thanks to its unique heating system and Dutch design.";
    case "Chemex":
      return "This pour-over coffee maker is known for its elegant design and ability to brew a clear, flavorful cup of coffee. It's a perfect choice for those who enjoy a more hands-on brewing experience.";
    case "Aeropress":
      return "This portable coffee maker is perfect for those who love to enjoy a great cup of coffee on the go. It's compact, versatile, and delivers a strong, flavorful brew.";
    default:
      return "";
  }
};

export default KitchensBest;
