import React from 'react';
import { Link } from 'react-router-dom';
import './CompactArticleList.css';
// Import the icons
import airpurifierbackdrop from 'src/assets/img/webp/airpurifierbackdrop.webp';
import hairdryerbackdrop from 'src/assets/img/webp/hairdryerbackdrop.webp';
import smartlockbackdrop from 'src/assets/img/webp/smartlockbackdrop.webp';
import standingdeskbackdrop from 'src/assets/img/webp/standingdeskbackdrop.webp';
import fitnesstrackerbackdrop from 'src/assets/img/webp/fitnesstrackerbackdrop.webp';
import multivitamin from 'src/assets/img/webp/multivitamin.webp';
import proteinpowder from 'src/assets/img/webp/proteinpowder.webp';

const articles = [
  {
    title: "The Benefits of Multivitamins",
  link: "/articles/Multivitamin-Benefits",
    lastUpdated: "August 6, 2024",
    icon: multivitamin
},
{
  title: "The Health Benefits of Protein Powders",
  link: "/articles/protein-powder-benefits",
  lastUpdated: "August 6, 2024",
  icon: proteinpowder
},
  {
    title: "The Health Benefits of Air Purifiers",
    link: "/articles/air-purifier-benefits",
    lastUpdated: "June 26, 2024",
    icon: airpurifierbackdrop
  },
    {
    title: "The Benefits of Standing Desks",
    link: "/articles/standing-desk-benefits",
    lastUpdated: "June 24, 2024",
    icon: standingdeskbackdrop
  },    {
    title: "The Health Benefits of Fitness Trackers",
    link: "/articles/fitness-tracker-benefits",
    lastUpdated: "June 24, 2024",
    icon: fitnesstrackerbackdrop
  },
  // Add more articles here
];

const CompactArticleList = () => {
  return (
    <div className="compact-article-list">
      <h2>Explore the Benefits</h2>
      <ul>
        {articles.map((article, index) => (
          <li key={index}>
            <Link to={article.link}>
              <img src={article.icon} alt="" className="article-icon" loading="lazy" width="34" height="34" />
             {article.title}
              <span className="article-date">{article.lastUpdated}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompactArticleList;